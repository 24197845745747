import ControlService from "../services/control.service";

export async function getPathsAndGoals(map_id) {
  try {
    const response = await ControlService.getPathsAndGoalsService(map_id);

    return Promise.resolve(response.data);
  } catch (err) {
    console.error(err);
    return Promise.resolve({ error: err });
  }
}

export async function getMap(robot_id, map_name) {
  try {
    const response = await ControlService.getMapService(robot_id, map_name);

    return Promise.resolve(response.data);
  } catch (err) {
    console.error(err);
    return Promise.resolve({ error: err });
  }
}

export async function sendControlCoord(robot_id, destination) {
  try {
    const response = await ControlService.sendControlCoordService(
      robot_id,
      destination
    );

    return Promise.resolve(response.data);
  } catch (err) {
    console.error(err);
    return Promise.resolve({ error: err });
  }
}

export async function sendControlPath(robot_id, path) {
  try {
    const response = await ControlService.sendControlPathService(
      robot_id,
      path
    );

    return Promise.resolve(response.data);
  } catch (err) {
    console.error(err);
    return Promise.resolve({ error: err });
  }
}

export async function sendControlPathName(robot_id, map_name, path_name) {
  try {
    const response = await ControlService.sendControlPathNameService(
      robot_id,
      map_name,
      path_name
    );

    return Promise.resolve(response.data);
  } catch (err) {
    console.error(err);
    return Promise.resolve({ error: err });
  }
}

export async function sendModeRequest(robot_id, mode) {
  try {
    const response = await ControlService.sendModeRequestService(
      robot_id,
      mode
    );

    return Promise.resolve(response.data);
  } catch (err) {
    console.error(err);
    return Promise.resolve({ error: err });
  }
}
