import jwt_decode from "jwt-decode";
import React, { useCallback } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getUserRoles, logout } from "../actions/login_auth.action";

function GuardedRoute(props) {
  const navigate = useNavigate();

  const checkToken = useCallback(() => {
    let currentDate = new Date();
    const token = localStorage.getItem("access_token");
    try {
      const decodedToken = jwt_decode(token);
      const roles = getUserRoles();

      let contains_role = false;
      props.expectedRole.forEach((expectedRole) => {
        if (roles.includes(expectedRole)) {
          contains_role = true;
        }
      });

      // JWT exp is in seconds
      if (
        roles.length === 0 ||
        !contains_role ||
        decodedToken.exp * 1000 < currentDate.getTime()
      ) {
        console.error("Not logged in, wrong user role or token expired.");
        logout(navigate);
        return false;
      }

      return true;
    } catch (error) {
      logout(navigate);
    }
    return false;
  }, [props, navigate]);

  return (
    <React.Fragment>
      {checkToken() ? props.children : <Navigate to="/login" />}
    </React.Fragment>
  );
}

export default GuardedRoute;
