import FleetService from "../services/fleet.service";

export async function getFleetData() {
  try {
    const response = await FleetService.getFleetDataService();

    return Promise.resolve(response.data);
  } catch (err) {
    console.error(err);
    return Promise.resolve({ error: err });
  }
}
