import React from "react";
import Home from "./home";

function HomePage() {
  document.title = "Home | Cyberworks Robotics Fleet Management";
  return (
    <React.Fragment>
      <Home />
    </React.Fragment>
  );
}

export default HomePage;
