import React from "react";
import { Typography } from "@mui/material";

function UserManagement() {
  return (
    <React.Fragment>
      <Typography paragraph variant="h4" color={"white"}>
        Some user management page coming soon...
      </Typography>
    </React.Fragment>
  );
}

export default UserManagement;
