import AuthService from "../services/login_auth.service";

import jwt_decode from "jwt-decode";

export async function login(username, password) {
  /**
   * Method to process login request by authenticating with remote key cloak server
   * If login is successful local storage is added with access token and refresh token
   * If login is not successful local storage is cleared
   * @param username (string)
   * @param password (string)
   * @return Promise<Response>
   */
  try {
    const response = await AuthService.loginService(username, password);

    if (response.status === 200) {
      localStorage.setItem("access_token", response.data.access_token);
      localStorage.setItem("refresh_token", response.data.refresh_token);
    } else if (response.status > 200) {
      localStorage.clear();
    }

    return Promise.resolve(response);
  } catch (err) {
    console.error(err);
    return Promise.resolve({ error: err });
  }
}

export async function refreshToken() {
  /**
   * Method to process refresh token request by refreshing token with remote key cloak server
   * If refresh is successful local storage is added with access token and refresh token
   * If refresh is not successful local storage is cleared
   * @return Promise<Response>
   */
  try {
    const response = await AuthService.refreshTokenService(
      localStorage.getItem("refresh_token")
    );

    if (response.status === 200) {
      localStorage.setItem("access_token", response.data.access_token);
      localStorage.setItem("refresh_token", response.data.refresh_token);
    } else if (response.status > 200) {
      localStorage.clear();
    }

    return Promise.resolve(response);
  } catch (err) {
    console.error(err);
    return Promise.resolve({ error: err });
  }
}

export async function logout(navigate) {
  /**
   * Method to process logout request by logging out from key cloak server
   * Local storage is cleared
   */
  try {
    await AuthService.logoutService(localStorage.getItem("refresh_token"));
  } catch (err) {
    console.error("Error logging out: ", err);
  }
  localStorage.clear();
  navigate("/login", { replace: true });
}

export function getUserId() {
  /**
   * Method to get user id from encoded local storage
   * @return user id
   */
  const token = localStorage.getItem("access_token");
  try {
    const decodedToken = jwt_decode(token);
    return decodedToken.sub;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function getUserName() {
  /**
   * Method to get user name from encoded local storage
   * @return user name
   */
  const token = localStorage.getItem("access_token");
  try {
    if (!token) return "";
    const decodedToken = jwt_decode(token);
    return decodedToken.preferred_username;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function getUserFullName() {
  /**
   * Method to get user's full name from encoded local storage
   * @return user name
   */
  const token = localStorage.getItem("access_token");
  try {
    if (!token) return "";
    const decodedToken = jwt_decode(token);
    return decodedToken.name;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function getUserRoles() {
  /**
   * Method to get user role from encoded local storage
   * @return role
   */
  const token = localStorage.getItem("access_token");
  try {
    let roles = [];
    const decodedToken = jwt_decode(token);
    decodedToken.realm_access.roles.forEach((r) => {
      roles.push(r);
    });

    return roles;
  } catch (error) {
    return null;
  }
}
