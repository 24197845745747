import React, { useState } from "react";
import { Box, Grid, Slider, Typography, styled } from "@mui/material";

const marks = [
  {
    value: 0.1,
    label: "",
  },
  {
    value: 0.2,
    label: "",
  },
  {
    value: 0.3,
    label: "",
  },
  {
    value: 0.4,
    label: "",
  },
  {
    value: 0.5,
    label: "",
  },
  {
    value: 0.6,
    label: "",
  },
  {
    value: 0.7,
    label: "",
  },
  {
    value: 0.8,
    label: "",
  },
  {
    value: 0.9,
    label: "",
  },
  {
    value: 1.0,
    label: "",
  },
];

const CustomSlider = styled(Slider)({
  color: "#8ccffd",
  flexGrow: 1,
  padding: 0,
  "& .MuiSlider-rail": {
    border: "none",
    borderRadius: "0",
    top: 0,
    bottom: 0,
    transform: "none",
    height: "auto",
    //color: "#e2e2e2",
  },
  "& .MuiSlider-track": {
    border: "none",
    borderRadius: "0",
    top: 0,
    bottom: 0,
    transform: "none",
    height: "auto",
  },
  "& .MuiSlider-mark": {
    border: "none",
    borderRadius: "0",
    top: 0,
    bottom: 0,
    transform: "translate(-2px, 0)",
    height: "auto",
    width: "4px",
    backgroundColor: "white",
  },
  "& .MuiSlider-thumb": {
    display: "none",
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#8ccffd",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

function valueText(value) {
  return `${value} m/s`;
}

function SpeedSlider(props) {
  const { currentSpeed, setCurrentSpeed } = props;

  const handleChange = (event, newValue) => {
    setCurrentSpeed(newValue);
  };

  return (
    <React.Fragment>
      <Grid container sx={{ paddingRight: "2rem", paddingLeft: "2rem" }}>
        <Grid
          display="flex"
          item
          xs={6}
          sm={6}
          md={6}
          lg={4}
          xl={4}
          sx={{
            borderBottom: "5px solid #8ccffd",
          }}
        >
          <Box
            display="flex"
            sx={{
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <Typography sx={{ whiteSpace: "nowrap", color: "#808080" }}>
              Speed
            </Typography>
            <Typography sx={{ whiteSpace: "nowrap" }}>
              {currentSpeed} m/s
            </Typography>
          </Box>
        </Grid>
        <Grid
          display="flex"
          item
          xs={6}
          sm={6}
          md={6}
          lg={8}
          xl={8}
          sx={{
            flexDirection: "column",
          }}
        >
          <CustomSlider
            getAriaLabel={() => "Speed"}
            getAriaValueText={valueText}
            value={currentSpeed}
            onChange={handleChange}
            valueLabelDisplay="auto"
            marks={marks}
            min={0}
            max={1}
            step={0.1}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SpeedSlider;
