import get_rest_instance from "../../shared/services/common.service";

class MapEditService {
  getMapsService(fleet_id) {
    return get_rest_instance("server").get("/maps", {
      params: { fleet_id: fleet_id },
    });
  }

  getMapByIdService(map_id) {
    return get_rest_instance("server").get("/map_by_id", {
      params: { map_id: map_id },
    });
  }

  saveObstacleLayerService(robot_id, map_name, obstacles) {
    return get_rest_instance("server").post("/obstacle_layer", {
      robot_id: robot_id,
      map_name: map_name,
      obstacles: obstacles,
    });
  }

  getObstacleLayerService(fleet_id, robot_id, map_name) {
    return get_rest_instance("server").get("/obstacle_layer", {
      params: {
        fleet_id: fleet_id,
        robot_id: robot_id,
        map_name: map_name,
      },
    });
  }
}

export default new MapEditService();
