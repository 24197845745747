import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { Box, Link, Typography } from "@mui/material";
import RobotMapList from "./robot_map_list";
import { FleetContext } from "../../shared/components/page_base";

function MapEditListPage() {
  document.title = "Map Editing | Cyberworks Robotics Fleet Management";
  const navigate = useNavigate();
  let { fleetId } = useParams();
  // eslint-disable-next-line no-unused-vars
  const { fleetData } = useContext(FleetContext);
  const [selectedFleet, setSelectedFleet] = useState(null);

  const selectMap = useCallback(
    (robot_id, map_name) => {
      console.log("Selected robot and map", robot_id, map_name);
      navigate("/map_edit/" + fleetId + "/" + robot_id + "/" + map_name);
    },
    [fleetId, navigate]
  );

  useEffect(() => {
    if (fleetData) {
      fleetData.forEach((fleet) => {
        if (fleet.id === fleetId) {
          setSelectedFleet(fleet);
        }
      });
    }
  }, [fleetData, fleetId]);

  return (
    <React.Fragment>
      <Box sx={{ padding: "1.5rem" }}>
        <Box display={"flex"} style={{ gap: 5 }}>
          <Link
            component="button"
            onClick={() => {
              navigate("/home/" + fleetId);
            }}
            underline="none"
            style={{ color: "white" }}
          >
            Home
          </Link>
          <Typography color="white">/</Typography>
          <Link
            component="button"
            onClick={() => {
              navigate("/map_edit/" + fleetId);
            }}
            underline="none"
            style={{ color: "white" }}
          >
            {selectedFleet ? selectedFleet.name : "N/A"}
          </Link>
        </Box>
        <Typography paragraph variant="h4" color={"white"}>
          Select a robot and a map to edit
        </Typography>
      </Box>
      <RobotMapList selectedFleet={selectedFleet} selectMap={selectMap} />
    </React.Fragment>
  );
}

export default MapEditListPage;
