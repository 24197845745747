import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Grid, Button } from "@mui/material";
import keyboard_triangle from "../../assets/images/keyboard_triangle.svg";
import { useInterval } from "../hooks/useInterval";

function KeyboardControl(props) {
  const { socket, localUsername, roomName, currentSpeed } = props;
  const keysPressed = useRef(new Set([]));
  /// Using a state to update the keyboard buttons background since a ref won't rerender on update but I don't it's easier to use a ref for the actual control
  const [upPressed, setUpPressed] = useState(false);
  const [downPressed, setDownPressed] = useState(false);
  const [leftPressed, setLeftPressed] = useState(false);
  const [rightPressed, setRightPressed] = useState(false);
  const move = useRef(null);

  const updateMove = useCallback(() => {
    if (keysPressed.current.size === 0) {
      move.current = { type: "stop" };
    } else {
      const linear =
        (keysPressed.current.has("up") ? currentSpeed : 0.0) -
        (keysPressed.current.has("down") ? currentSpeed : 0.0);
      const angular =
        (keysPressed.current.has("left") ? currentSpeed : 0.0) -
        (keysPressed.current.has("right") ? currentSpeed : 0.0);
      move.current = { type: "move", linear: linear, angular: angular };
    }
  }, [currentSpeed]);

  const keyDown = useCallback(
    (event) => {
      if (
        (event.key === "ArrowUp" || event.key === "w" || event.key === "i") &&
        !keysPressed.current.has("up")
      ) {
        keysPressed.current.add("up");
        setUpPressed(true);
        updateMove();
      } else if (
        (event.key === "ArrowDown" || event.key === "s" || event.key === "k") &&
        !keysPressed.current.has("down")
      ) {
        keysPressed.current.add("down");
        setDownPressed(true);
        updateMove();
      } else if (
        (event.key === "ArrowLeft" || event.key === "a" || event.key === "j") &&
        !keysPressed.current.has("left")
      ) {
        keysPressed.current.add("left");
        setLeftPressed(true);
        updateMove();
      } else if (
        (event.key === "ArrowRight" ||
          event.key === "d" ||
          event.key === "l") &&
        !keysPressed.current.has("right")
      ) {
        keysPressed.current.add("right");
        setRightPressed(true);
        updateMove();
      }
    },
    [updateMove]
  );

  const keyUp = useCallback(
    (event) => {
      if (
        (event.key === "ArrowUp" || event.key === "w" || event.key === "i") &&
        keysPressed.current.has("up")
      ) {
        keysPressed.current.delete("up");
        setUpPressed(false);
        updateMove();
      } else if (
        (event.key === "ArrowDown" || event.key === "s" || event.key === "k") &&
        keysPressed.current.has("down")
      ) {
        keysPressed.current.delete("down");
        setDownPressed(false);
        updateMove();
      } else if (
        (event.key === "ArrowLeft" || event.key === "a" || event.key === "j") &&
        keysPressed.current.has("left")
      ) {
        keysPressed.current.delete("left");
        setLeftPressed(false);
        updateMove();
      } else if (
        (event.key === "ArrowRight" ||
          event.key === "d" ||
          event.key === "l") &&
        keysPressed.current.has("right")
      ) {
        keysPressed.current.delete("right");
        setRightPressed(false);
        updateMove();
      }
    },
    [updateMove]
  );

  useInterval(() => {
    if (move.current) {
      socket.current.emit("data", {
        username: localUsername,
        room: roomName,
        data: { type: "teleop", twist: move.current },
      });
      if (move.current.type === "stop") {
        move.current = null;
      }
    }
  }, 100);

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = useCallback(() => {
    keysPressed.current.clear();
    setUpPressed(false);
    setDownPressed(false);
    setLeftPressed(false);
    setRightPressed(false);
    updateMove();
  }, [updateMove]);

  useEffect(() => {
    document.addEventListener("keydown", keyDown, false);
    document.addEventListener("keyup", keyUp, false);
    window.addEventListener("blur", onBlur);

    return () => {
      document.removeEventListener("keydown", keyDown, false);
      document.removeEventListener("keyup", keyUp, false);
      window.removeEventListener("blur", onBlur);
    };
  }, [keyDown, keyUp, onBlur]);

  return (
    <React.Fragment>
      <Box
        display="flex"
        sx={{
          backgroundColor: "#bae2fe",
          borderRadius: "50%",
          justifyContent: "center",
          alignItems: "stretch",
          aspectRatio: "1",
          width: "300px",
        }}
      >
        <Box
          display="flex"
          sx={{
            flexGrow: 1,
            backgroundColor: "white",
            borderRadius: "50%",
            justifyContent: "center",
            alignItems: "center",
            aspectRatio: "1",
            margin: "2rem",
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          }}
        >
          <Box
            display="flex"
            sx={{
              flexDirection: "column",
              width: "170px",
              aspectRatio: "1",
            }}
          >
            <Grid container sx={{ flexBasis: 0, flexGrow: 1 }}>
              <Grid item xs={4} display="flex">
                <Box sx={{ padding: 0, flexGrow: 1, flexBasis: 0 }}></Box>
              </Grid>
              <Grid item xs={4} display="flex">
                <Button
                  sx={{
                    minWidth: "0",
                    padding: 0,
                    flexGrow: 1,
                    flexBasis: 0,
                    borderRadius: "4px 4px 0 0",
                    boxShadow: "0px -1px 5px 1px rgba(0, 0, 0, 0.25)",
                    backgroundColor: upPressed ? "rgba(24, 159, 250, 0.2)" : "",
                  }}
                  onMouseDown={() => {
                    keysPressed.current.add("up");
                    setUpPressed(true);
                    updateMove();
                  }}
                  onMouseUp={() => {
                    keysPressed.current.delete("up");
                    setUpPressed(false);
                    updateMove();
                  }}
                >
                  <img src={keyboard_triangle} alt="" sx={{ width: "32px" }} />
                </Button>
              </Grid>
              <Grid item xs={4} display="flex">
                <Box sx={{ padding: 0, flexGrow: 1, flexBasis: 0 }}></Box>
              </Grid>
            </Grid>
            <Grid container sx={{ flexBasis: 0, flexGrow: 1 }}>
              <Grid item xs={4} display="flex">
                <Button
                  sx={{
                    minWidth: "0",
                    padding: 0,
                    flexGrow: 1,
                    flexBasis: 0,
                    borderRadius: "4px 4px 0 0",
                    boxShadow: "0px -1px 5px 1px rgba(0, 0, 0, 0.25)",
                    transform: "rotate(-90deg)",
                    backgroundColor: leftPressed
                      ? "rgba(24, 159, 250, 0.2)"
                      : "",
                  }}
                  onMouseDown={() => {
                    keysPressed.current.add("left");
                    setLeftPressed(true);
                    updateMove();
                  }}
                  onMouseUp={() => {
                    keysPressed.current.delete("left");
                    setLeftPressed(false);
                    updateMove();
                  }}
                >
                  <img src={keyboard_triangle} alt="" sx={{ width: "32px" }} />
                </Button>
              </Grid>
              <Grid item xs={4} display="flex">
                <Box
                  sx={{
                    padding: 0,
                    flexGrow: 1,
                    flexBasis: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                  }}
                ></Box>
              </Grid>
              <Grid item xs={4} display="flex">
                <Button
                  sx={{
                    minWidth: "0",
                    padding: 0,
                    flexGrow: 1,
                    flexBasis: 0,
                    borderRadius: "4px 4px 0 0",
                    boxShadow: "0px -1px 5px 1px rgba(0, 0, 0, 0.25)",
                    transform: "rotate(90deg)",
                    backgroundColor: rightPressed
                      ? "rgba(24, 159, 250, 0.2)"
                      : "",
                  }}
                  onMouseDown={() => {
                    keysPressed.current.add("right");
                    setRightPressed(true);
                    updateMove();
                  }}
                  onMouseUp={() => {
                    keysPressed.current.delete("right");
                    setRightPressed(false);
                    updateMove();
                  }}
                >
                  <img src={keyboard_triangle} alt="" sx={{ width: "32px" }} />
                </Button>
              </Grid>
            </Grid>
            <Grid container sx={{ flexBasis: 0, flexGrow: 1 }}>
              <Grid item xs={4} display="flex">
                <Box sx={{ padding: 0, flexGrow: 1, flexBasis: 0 }}></Box>
              </Grid>
              <Grid item xs={4} display="flex">
                <Button
                  sx={{
                    minWidth: "0",
                    padding: 0,
                    flexGrow: 1,
                    flexBasis: 0,
                    borderRadius: "4px 4px 0 0",
                    boxShadow: "0px -1px 5px 1px rgba(0, 0, 0, 0.25)",
                    transform: "rotate(180deg)",
                    backgroundColor: downPressed
                      ? "rgba(24, 159, 250, 0.2)"
                      : "",
                  }}
                  onMouseDown={() => {
                    keysPressed.current.add("down");
                    setDownPressed(true);
                    updateMove();
                  }}
                  onMouseUp={() => {
                    keysPressed.current.delete("down");
                    setDownPressed(false);
                    updateMove();
                  }}
                >
                  <img src={keyboard_triangle} alt="" sx={{ width: "32px" }} />
                </Button>
              </Grid>
              <Grid item xs={4} display="flex">
                <Box sx={{ padding: 0, flexGrow: 1, flexBasis: 0 }}></Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default KeyboardControl;
