import { Box, Link, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import RobotControl from "./robot_control";
import { useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "../../shared/hooks/mediaQuery";
import { useWindowDimensions } from "../../shared/hooks/useWindowDimensions";
import { FleetContext } from "../../shared/components/page_base";

function DispatchControlRobotPage() {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const windowSize = useWindowDimensions();
  const [selectedRobot, setSelectedRobot] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const { fleetData } = useContext(FleetContext);
  const [selectedFleet, setSelectedFleet] = useState(null);

  const navigate = useNavigate();
  let { fleetId, robotId } = useParams();

  useEffect(() => {
    fleetData.forEach((fleet) => {
      if (fleet.id === fleetId) {
        setSelectedFleet(fleet);
        setSelectedRobot(
          fleet.robots.find((item) => {
            return item.id === robotId;
          })
        );
      }
    });
  }, [fleetData, fleetId, robotId]);

  return (
    <React.Fragment>
      <Box sx={{ padding: "1.5rem" }}>
        <Box
          display={"flex"}
          style={{
            gap: 5,
            alignItems: "center",
            maxWidth: windowSize.width - 48,
          }}
        >
          <Link
            component="button"
            onClick={() => {
              navigate("/home/" + fleetId);
            }}
            underline="none"
            style={{ color: "white" }}
          >
            Home
          </Link>
          <Typography color="white">/</Typography>
          <Link
            component="button"
            onClick={() => {
              setSelectedRobot(null);
              navigate("/dispatch_control/" + fleetId);
            }}
            underline="none"
            style={{ color: "white" }}
            noWrap
          >
            {selectedFleet ? selectedFleet.name : "N/A"}
          </Link>
          <Typography color="white">/</Typography>
          <Link
            component="button"
            underline="none"
            style={{ color: "white" }}
            noWrap
          >
            {selectedRobot ? selectedRobot.name : "N/A"}
          </Link>
        </Box>
        {!isMobile && (
          <Box>
            <Typography variant="h4" color={"white"}>
              {selectedFleet ? selectedFleet.name : "N/A"}
            </Typography>
            <Typography color="#a0a7b0" variant="body1">
              {selectedFleet ? selectedFleet.address : "N/A"}
            </Typography>
          </Box>
        )}
      </Box>
      {selectedRobot ? <RobotControl robot={selectedRobot} /> : ""}
    </React.Fragment>
  );
}

export default DispatchControlRobotPage;
