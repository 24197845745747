import React from "react";
import { Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function ShapesList(props) {
  const { shapes, setHighlightedId, deleteShapeById, expanded, setExpanded } =
    props;

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : "");
  };

  return (
    <React.Fragment>
      <Accordion
        expanded={expanded === "points"}
        onChange={handleChange("points")}
      >
        <AccordionSummary
          aria-controls="points-content"
          id="points-header"
          onMouseEnter={() => {
            setHighlightedId("points");
          }}
          onMouseLeave={() => {
            setHighlightedId(null);
          }}
        >
          <Typography>
            Points ({shapes && shapes.points ? shapes.points.size : "0"})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {shapes && shapes.points && shapes.points.size > 0 ? (
            Array.from(shapes.points.entries()).map((entry, index) => {
              const [id, pointGroup] = entry;
              return (
                <Box
                  key={"point-" + id}
                  onMouseEnter={() => {
                    setHighlightedId(id);
                  }}
                  onMouseLeave={() => {
                    setHighlightedId(null);
                  }}
                  display="flex"
                  sx={{ justifyContent: "space-between", alignItems: "center" }}
                >
                  <Typography>Point {index + 1}</Typography>
                  <IconButton
                    color="error"
                    aria-label="delete"
                    onClick={() => {
                      deleteShapeById("points", id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              );
            })
          ) : (
            <Typography>No points in the list</Typography>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "lines"}
        onChange={handleChange("lines")}
      >
        <AccordionSummary
          aria-controls="lines-content"
          id="lines-header"
          onMouseEnter={() => {
            setHighlightedId("lines");
          }}
          onMouseLeave={() => {
            setHighlightedId(null);
          }}
        >
          <Typography>
            Lines ({shapes && shapes.lines ? shapes.lines.size : "0"})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {shapes && shapes.lines && shapes.lines.size > 0 ? (
            Array.from(shapes.lines.entries()).map((entry, index) => {
              const [id, lineGroup] = entry;
              return (
                <Box
                  key={"line-" + id}
                  onMouseEnter={() => {
                    setHighlightedId(id);
                  }}
                  onMouseLeave={() => {
                    setHighlightedId(null);
                  }}
                  display="flex"
                  sx={{ justifyContent: "space-between", alignItems: "center" }}
                >
                  <Typography>Line {index + 1}</Typography>
                  <IconButton
                    color="error"
                    aria-label="delete"
                    onClick={() => {
                      deleteShapeById("lines", id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              );
            })
          ) : (
            <Typography>No lines in the list</Typography>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "circles"}
        onChange={handleChange("circles")}
      >
        <AccordionSummary
          aria-controls="circles-content"
          id="circles-header"
          onMouseEnter={() => {
            setHighlightedId("circles");
          }}
          onMouseLeave={() => {
            setHighlightedId(null);
          }}
        >
          <Typography>
            Circles ({shapes && shapes.circles ? shapes.circles.size : "0"})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {shapes && shapes.circles && shapes.circles.size > 0 ? (
            Array.from(shapes.circles.entries()).map((entry, index) => {
              const [id, circleGroup] = entry;
              return (
                <Box
                  key={"circle-" + id}
                  onMouseEnter={() => {
                    setHighlightedId(id);
                  }}
                  onMouseLeave={() => {
                    setHighlightedId(null);
                  }}
                  display="flex"
                  sx={{ justifyContent: "space-between", alignItems: "center" }}
                >
                  <Typography>Circle {index + 1}</Typography>
                  <IconButton
                    color="error"
                    aria-label="delete"
                    onClick={() => {
                      deleteShapeById("circles", id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              );
            })
          ) : (
            <Typography>No circles in the list</Typography>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "rectangles"}
        onChange={handleChange("rectangles")}
      >
        <AccordionSummary
          aria-controls="rectangles-content"
          id="rectangles-header"
          onMouseEnter={() => {
            setHighlightedId("rectangles");
          }}
          onMouseLeave={() => {
            setHighlightedId(null);
          }}
        >
          <Typography>
            Rectangles (
            {shapes && shapes.rectangles ? shapes.rectangles.size : "0"})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {shapes && shapes.rectangles && shapes.rectangles.size > 0 ? (
            Array.from(shapes.rectangles.entries()).map((entry, index) => {
              const [id, rectangleGroup] = entry;
              return (
                <Box
                  key={"rectangle-" + id}
                  onMouseEnter={() => {
                    setHighlightedId(id);
                  }}
                  onMouseLeave={() => {
                    setHighlightedId(null);
                  }}
                  display="flex"
                  sx={{ justifyContent: "space-between", alignItems: "center" }}
                >
                  <Typography>Rectangle {index + 1}</Typography>
                  <IconButton
                    color="error"
                    aria-label="delete"
                    onClick={() => {
                      deleteShapeById("rectangles", id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              );
            })
          ) : (
            <Typography>No rectangles in the list</Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}

export default ShapesList;
