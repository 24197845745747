import AutomaticControl from "../../shared/components/automatic_control";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import ManualControl from "../../shared/components/manual_control";
// import MapViewCanvas from "../../shared/components/map_view_canvas";
import MultiVideoView from "./multi_video_view";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import SlideshowVideoView from "./slideshow_video_view";
import {
  Box,
  Button,
  Collapse,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { io } from "socket.io-client";
import { useMediaQuery } from "../../shared/hooks/mediaQuery";
import { useParams } from "react-router-dom";
import { WEBSOCKET_URL } from "../../constant";
import { checkToken } from "../../shared/services/common.service";
import { useInterval } from "../../shared/hooks/useInterval";
import { FleetContext } from "../../shared/components/page_base";
import { getMap, getPathsAndGoals } from "../actions/control.action";
import MapView from "../../shared/components/map_view";

function RobotControl(props) {
  const { robot } = props;
  let { fleetId, robotId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 900px)");
  const [selectedAction, setSelectedAction] = useState("automatic");
  // TODO: Change to proper username from jwt
  const localUsername = "test_username";
  const roomName = fleetId + "_" + robotId;
  const control_socket = useRef(null);
  // const state_socket = useRef(null);
  const cameraIndex = useRef(0);
  //const [frontImage, setFrontImage] = useState(null);
  const [centerImage, setCenterImage] = useState(null);
  //const [backImage, setBackImage] = useState(null);
  const [bottomImage, setBottomImage] = useState(null);
  const [leftImage, setLeftImage] = useState(null);
  const [rightImage, setRightImage] = useState(null);
  const [topImage, setTopImage] = useState(null);
  const [wideImage, setWideImage] = useState(null);
  const { fleetData, robotStates } = useContext(FleetContext);
  const initMap = useRef(false);
  const initPathsAndGoals = useRef(false);
  const [map, setMap] = useState({});
  const [paths, setPaths] = useState([]);
  const [goals, setGoals] = useState([]);
  const [mapName, setMapName] = useState("");
  const [paused, setPaused] = useState(false);

  const onData = useCallback((data) => {
    const json_data = JSON.parse(data);
    if (json_data.type === "image") {
      const image_bytes = json_data.image.substring(
        2,
        json_data.image.length - 1
      );
      if (json_data.position === "front") {
        //setFrontImage("data:image/jpeg;base64," + image_bytes);
      } else if (json_data.position === "center") {
        setCenterImage("data:image/jpeg;base64," + image_bytes);
      } else if (json_data.position === "back") {
        //setBackImage("data:image/jpeg;base64," + image_bytes);
      } else if (json_data.position === "bottom") {
        setBottomImage("data:image/jpeg;base64," + image_bytes);
      } else if (json_data.position === "left") {
        setLeftImage("data:image/jpeg;base64," + image_bytes);
      } else if (json_data.position === "right") {
        setRightImage("data:image/jpeg;base64," + image_bytes);
      } else if (json_data.position === "top") {
        setTopImage("data:image/jpeg;base64," + image_bytes);
      } else if (json_data.position === "wide") {
        setWideImage("data:image/jpeg;base64," + image_bytes);
      } else if (json_data.position === "") {
        setTopImage("data:image/jpeg;base64," + image_bytes);
      } else {
        console.error("Unknown position for image", json_data.position);
      }
    }
  }, []);

  const changeCameras = useCallback(
    (index) => {
      cameraIndex.current = index;
      let cameraIds = [];
      if (robot.cameras.left_camera_active) {
        cameraIds.push("left_camera");
      }
      if (robot.cameras.bottom_camera_active) {
        cameraIds.push("bottom_camera");
      }
      if (robot.cameras.center_camera_active) {
        cameraIds.push("center_camera");
      }
      if (robot.cameras.right_camera_active) {
        cameraIds.push("right_camera");
      }
      if (robot.cameras.top_camera_active) {
        cameraIds.push("top_camera");
      }
      if (robot.cameras.wide_camera_active) {
        cameraIds.push("wide_camera");
      }
      control_socket.current.emit("data", {
        username: localUsername,
        room: roomName,
        data: {
          type: "start_video",
          cameras: [cameraIds[cameraIndex.current]],
        },
      });
    },
    [robot, roomName]
  );

  const onReady = useCallback(
    (data) => {
      const json_data = JSON.parse(data);
      if (json_data.username === "robot") {
        changeCameras(cameraIndex.current);
      }
    },
    [changeCameras]
  );

  useInterval(() => {
    if (robotStates && robotStates.has(robotId)) {
      if (!initMap.current) {
        console.log("Initializing map", robotStates.get(robotId));

        const map_name = robotStates.get(robotId).location.level_name;
        if (map_name) {
          setMapName(map_name);
          console.log("Getting map", map_name);
          getMap(robotId, map_name)
            .then(function (data) {
              console.log("Got map", data);
              setMap(data);
              initMap.current = true;
            })
            .catch((error) => {
              console.error(error);
            });
        }
      } else if (
        map &&
        Object.keys(map).length !== 0 &&
        !initPathsAndGoals.current
      ) {
        console.log("Initializing paths and goals for map", map["id"]);
        getPathsAndGoals(map["id"])
          .then(function (data) {
            console.log("Got paths", data["paths"]);
            setPaths(data["paths"]);
            console.log("Got goals", data["goals"]);
            data["goals"].sort((a, b) => {
              const nameA = a.name.toUpperCase();
              const nameB = b.name.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }

              return 0;
            });
            setGoals(data["goals"]);
            initPathsAndGoals.current = true;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }, 1000);

  useEffect(() => {
    // Setup the websocket to control the robot
    control_socket.current = io(WEBSOCKET_URL, {
      autoConnect: false,
      extraHeaders: {
        Authorization: "Bearer " + checkToken(),
      },
    });
    control_socket.current.on("data", onData);
    control_socket.current.on("ready", onReady);

    control_socket.current.connect();
    control_socket.current.emit("join", {
      username: localUsername,
      room: roomName,
    });
    /// The mobile version only need to stream the video currently being displayed
    if (isMobile) {
      changeCameras(0);
    } else {
      let cameraIds = [];
      if (robot.cameras.left_camera_active) {
        cameraIds.push("left_camera");
      }
      if (robot.cameras.bottom_camera_active) {
        cameraIds.push("bottom_camera");
      }
      if (robot.cameras.center_camera_active) {
        cameraIds.push("center_camera");
      }
      if (robot.cameras.right_camera_active) {
        cameraIds.push("right_camera");
      }
      if (robot.cameras.top_camera_active) {
        cameraIds.push("top_camera");
      }
      if (robot.cameras.wide_camera_active) {
        cameraIds.push("wide_camera");
      }

      //console.log("Starting video", cameraIds, roomName);
      control_socket.current.emit("data", {
        username: localUsername,
        room: roomName,
        data: {
          type: "start_video",
          cameras: cameraIds,
        },
      });
    }

    return function cleanup() {
      control_socket.current.off("data", onData);
      control_socket.current.off("ready", onReady);
      control_socket.current.disconnect();

      /*state_socket.current.off("robot_state", onRobotState);
      state_socket.current.disconnect();*/
    };
  }, []);

  return (
    <React.Fragment>
      <Box
        display="flex"
        sx={{
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        {isMobile ? (
          <SlideshowVideoView
            robot={robot}
            centerImage={centerImage}
            bottomImage={bottomImage}
            leftImage={leftImage}
            rightImage={rightImage}
            topImage={topImage}
            wideImage={wideImage}
            changeCameras={changeCameras}
          ></SlideshowVideoView>
        ) : (
          <MultiVideoView
            robot={robot}
            centerImage={centerImage}
            bottomImage={bottomImage}
            leftImage={leftImage}
            rightImage={rightImage}
            topImage={topImage}
            wideImage={wideImage}
          ></MultiVideoView>
        )}

        {isMobile ? (
          <Box
            display="flex"
            sx={{
              flexGrow: 1,
              flexDirection: "column",
            }}
          >
            <Collapse in={selectedAction === "map"}>
              <Button
                variant="contained"
                color="info"
                sx={{
                  borderRadius: "0px",
                  borderBottom: "2px solid " + theme.palette.primary.main,
                  flexDirection: "column",
                  width: "inherit",
                  boxShadow: "none",
                  "&:hover": {
                    color: theme.palette.info.contrastText,
                    backgroundColor: "#dddddd",
                  },
                }}
                onClick={() => {
                  setSelectedAction("automatic");
                }}
              >
                <KeyboardDoubleArrowUpIcon />
                <Typography
                  sx={{
                    fontWeight: "700",
                  }}
                >
                  Go to control
                </Typography>
              </Button>{" "}
            </Collapse>
            {selectedAction !== "map" && (
              <Box display="flex">
                <Button
                  variant="contained"
                  color="info"
                  sx={{
                    borderRadius: "0px",
                    flexGrow: 1,
                    flexBasis: 0,
                    boxShadow: "none",
                    borderRight: "1px solid " + theme.palette.primary.main,
                    borderBottom:
                      selectedAction !== "automatic"
                        ? "2px solid " + theme.palette.primary.main
                        : "",
                    paddingTop: "0.75rem",
                    paddingBottom: "0.75rem",
                    color: selectedAction === "automatic" ? "" : "#bae2fe",
                    "&:hover": {
                      color: theme.palette.info.contrastText,
                      backgroundColor: "#dddddd",
                      borderBottom: "2px solid " + theme.palette.primary.main,
                    },
                  }}
                  onClick={() => {
                    setSelectedAction("automatic");
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                    }}
                  >
                    Automatic control
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  sx={{
                    borderRadius: "0px",
                    flexGrow: 1,
                    flexBasis: 0,
                    boxShadow: "none",
                    borderLeft: "1px solid " + theme.palette.primary.main,
                    borderBottom:
                      selectedAction !== "manual"
                        ? "2px solid " + theme.palette.primary.main
                        : "",
                    paddingTop: "0.75rem",
                    paddingBottom: "0.75rem",
                    color: selectedAction === "manual" ? "" : "#bae2fe",
                    "&:hover": {
                      color: theme.palette.info.contrastText,
                      backgroundColor: "#dddddd",
                      borderBottom: "2px solid " + theme.palette.primary.main,
                    },
                  }}
                  onClick={() => {
                    setSelectedAction("manual");
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                    }}
                  >
                    Manual control
                  </Typography>
                </Button>
              </Box>
            )}
            <Box display="flex" sx={{ flexGrow: 1, flexBasis: 0 }}>
              {selectedAction === "automatic" ? (
                <AutomaticControl
                  showHeader={false}
                  paths={paths}
                  goals={goals}
                  mapName={mapName}
                  paused={paused}
                  setPaused={setPaused}
                  robot={robot}
                />
              ) : selectedAction === "manual" ? (
                <ManualControl
                  showHeader={false}
                  socket={control_socket}
                  localUsername={localUsername}
                  roomName={roomName}
                />
              ) : (
                <MapView map={map} />
              )}
            </Box>

            <Collapse in={selectedAction !== "map"}>
              <Button
                variant="contained"
                color="info"
                sx={{
                  borderRadius: "0px",
                  borderTop: "2px solid " + theme.palette.primary.main,
                  flexDirection: "column",
                  width: "inherit",
                  boxShadow: "none",
                  "&:hover": {
                    color: theme.palette.info.contrastText,
                    backgroundColor: "#dddddd",
                  },
                }}
                onClick={() => {
                  setSelectedAction("map");
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                  }}
                >
                  Go to map
                </Typography>
                <KeyboardDoubleArrowDownIcon />
              </Button>
            </Collapse>
          </Box>
        ) : (
          <Box
            display="flex"
            sx={{
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                sx={{
                  display: "flex",
                }}
              >
                <MapView map={map} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}
                sx={{
                  display: "flex",
                  backgroundColor: "white",
                  zIndex: 1,
                }}
              >
                <AutomaticControl
                  showHeader={true}
                  paths={paths}
                  goals={goals}
                  mapName={mapName}
                  paused={paused}
                  setPaused={setPaused}
                  robot={robot}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}
                sx={{
                  display: "flex",
                  backgroundColor: "white",
                  zIndex: 1,
                }}
              >
                <ManualControl
                  showHeader={true}
                  socket={control_socket}
                  localUsername={localUsername}
                  roomName={roomName}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
}
/*
<Divider
  orientation="vertical"
  sx={{ backgroundColor: "#cccccc", width: "2px" }}
/>
*/

export default RobotControl;
