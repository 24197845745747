import React, { useContext, useState } from "react";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { useMediaQuery } from "../../shared/hooks/mediaQuery";
import { FleetContext } from "../../shared/components/page_base";
import { useInterval } from "../../shared/hooks/useInterval";

function RobotList(props) {
  const { selectedFleet, selectRobot } = props;
  const { robotStates } = useContext(FleetContext);
  const [fleetState, setFleetState] = useState(new Map());
  const theme = useTheme();

  const isLg = useMediaQuery("(min-width: 1200px)");
  const isMd = useMediaQuery("(min-width: 900px)");
  const isSm = useMediaQuery("(min-width: 768px)");

  useInterval(() => {
    if (selectedFleet && robotStates && robotStates.size !== 0) {
      let tmpFleetState = new Map();
      selectedFleet.robots.forEach((robotItem) => {
        tmpFleetState.set(robotItem.id, robotStates.get(robotItem.id));
      });
      setFleetState(tmpFleetState);
    }
  }, 1000);

  return (
    <React.Fragment>
      <Box
        display="flex"
        style={{
          flexDirection: "column",
          gap: "0.5rem",
          paddingLeft: "1.5rem",
          paddingRight: "1.5rem",
        }}
      >
        <Grid
          container
          style={theme.background_primary}
          sx={{
            paddingLeft: "1rem",
            paddingRight: "1rem",
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
          }}
        >
          <Grid item xs={4} sm={4} md={3} lg={2} xl={2}>
            <Typography style={{ fontWeight: 700 }}>Name</Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={3} lg={2} xl={2}>
            <Typography style={{ fontWeight: 700 }}>Status</Typography>
          </Grid>
          <Grid
            item
            xs={0}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            style={{ display: isSm ? "" : "none" }}
          >
            <Typography style={{ fontWeight: 700 }}>Battery</Typography>
          </Grid>
          <Grid
            item
            xs={0}
            sm={0}
            md={0}
            lg={3}
            xl={3}
            style={{ display: isLg ? "" : "none" }}
          >
            <Typography style={{ fontWeight: 700 }}>Task</Typography>
          </Grid>
          <Grid
            item
            xs={0}
            sm={0}
            md={3}
            lg={2}
            xl={2}
            style={{ display: isMd ? "" : "none" }}
          >
            <Typography style={{ fontWeight: 700 }}>Map</Typography>
          </Grid>
          <Grid item xs={4} sm={2} md={1} lg={1} xl={1}>
            <Typography style={{ fontWeight: 700 }}>Active</Typography>
          </Grid>
        </Grid>

        {selectedFleet &&
        selectedFleet.robots &&
        selectedFleet.robots.length !== 0
          ? selectedFleet.robots.map((robotItem) => (
              <Button
                key={robotItem.id}
                style={theme.linear_background_tertiary}
                sx={{
                  padding: "1rem",
                  borderRadius: 0,
                  textAlign: "left",
                  textTransform: "none",
                  color: "black",
                }}
                onClick={() => {
                  selectRobot(robotItem.id);
                }}
              >
                <Grid container>
                  <Grid item xs={4} sm={4} md={3} lg={2} xl={2}>
                    <Typography>{robotItem.name}</Typography>
                  </Grid>
                  <Grid item xs={4} sm={4} md={3} lg={2} xl={2}>
                    <Typography>
                      {fleetState &&
                      fleetState.has(robotItem.id) &&
                      "mode" in fleetState.get(robotItem.id)
                        ? fleetState
                            .get(robotItem.id)
                            ["mode"].substring(
                              16,
                              fleetState.get(robotItem.id)["mode"].length - 1
                            )
                        : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={0}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    style={{ display: isSm ? "" : "none" }}
                  >
                    <Typography>
                      {fleetState &&
                      fleetState.has(robotItem.id) &&
                      fleetState.has(robotItem.id)["battery_percent"]
                        ? fleetState.get(robotItem.id)["battery_percent"]
                        : "N/A"}
                      %
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={0}
                    sm={0}
                    md={0}
                    lg={3}
                    xl={3}
                    style={{ display: isLg ? "" : "none" }}
                  >
                    <Typography>
                      {fleetState &&
                      fleetState.has(robotItem.id) &&
                      fleetState.get(robotItem.id)["task_id"]
                        ? fleetState.get(robotItem.id)["task_id"]
                        : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={0}
                    sm={0}
                    md={3}
                    lg={2}
                    xl={2}
                    style={{ display: isMd ? "" : "none" }}
                  >
                    <Typography>
                      {fleetState &&
                      fleetState.has(robotItem.id) &&
                      fleetState.get(robotItem.id)["location"]["level_name"]
                        ? fleetState.get(robotItem.id)["location"]["level_name"]
                        : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={2} md={1} lg={1} xl={1}>
                    <Typography>
                      {robotItem.active ? "True" : "False"}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            ))
          : "No robot available, try refreshing the page"}
      </Box>
    </React.Fragment>
  );
}

export default RobotList;
