import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import MuiAlert from "@mui/material/Alert";
import { Typography, useTheme } from "@mui/material";
import { getUserRoles, login } from "../actions/login_auth.action";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// TODO: Center login card properly, check on big screen
function LoginCard(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [open, setOpen] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const redirectUser = useCallback(() => {
    const roles = getUserRoles();
    if (roles && (roles.includes("user") || roles.includes("manager"))) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setTimeout(() => {
      redirectUser();
    }, 500);
  }, [redirectUser]);

  const loginAndAuthorize = useCallback(
    (data) => {
      login(data.username, data.password).then((response) => {
        try {
          if (response.status === 200) {
            redirectUser();
          } else {
            setSnackbarMessage("Username or password provided incorrect");
            setOpen(true);
          }
        } catch (e) {
          console.error(e);
          setSnackbarMessage("Unexpected error in login api");
          setOpen(true);
        }
      });
    },
    [redirectUser]
  );

  const enterEvent = useCallback(
    (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit(loginAndAuthorize)();
      }
    },
    [loginAndAuthorize, handleSubmit]
  );

  useEffect(() => {
    document.addEventListener("keydown", enterEvent);
    return () => document.removeEventListener("keydown", enterEvent);
  }, [enterEvent]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Grow in={true}>
        <FormControl>
          <Card elevation={10}>
            <CardHeader
              title={
                <React.Fragment>
                  <Grid
                    container
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={10} sm={11} md={11} lg={11}>
                      <Typography style={{ fontWeight: 600, fontSize: 18 }}>
                        Fleet Management Portal - Sign in
                      </Typography>
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
              style={theme.background_primary}
              titleTypographyProps={{ variant: "h5", fontWeight: 800 }}
            />
            <CardContent>
              <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} sm={11} md={11} lg={11}>
                  <TextField
                    variant="outlined"
                    type="text"
                    id="username"
                    placeholder="Username"
                    label="Username"
                    fullWidth={true}
                    error={errors.username?.type === "required"}
                    helperText={
                      errors.username?.type === "required" && "Required"
                    }
                    {...register("username", {
                      required: true,
                    })}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} sm={11} md={11} lg={11}>
                  <TextField
                    variant="outlined"
                    type="password"
                    id="password"
                    placeholder="Password"
                    label="Password"
                    fullWidth={true}
                    error={errors.password?.type === "required"}
                    helperText={
                      errors.password?.type === "required" && "Required"
                    }
                    {...register("password", {
                      required: true,
                    })}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} sm={11} md={11} lg={11}>
                  <Button
                    variant="contained"
                    fullWidth={true}
                    style={{
                      fontWeight: 1000,
                    }}
                    onClick={handleSubmit(loginAndAuthorize)}
                  >
                    Sign in
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </FormControl>
      </Grow>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default LoginCard;
