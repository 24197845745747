import React from "react";
import Monitor from "./monitor";

function MonitorPage() {
  document.title = "Monitor | Cyberworks Robotics Fleet Management";
  return (
    <React.Fragment>
      <Monitor />
    </React.Fragment>
  );
}

export default MonitorPage;
