import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { Box, Link, Typography } from "@mui/material";
import RobotList from "./robot_list";
import { FleetContext } from "../../shared/components/page_base";

function DispatchControlListPage() {
  document.title = "Dispatch Control | Cyberworks Robotics Fleet Management";
  const navigate = useNavigate();
  let { fleetId } = useParams();
  // eslint-disable-next-line no-unused-vars
  const { fleetData } = useContext(FleetContext);
  const [selectedFleet, setSelectedFleet] = useState(null);

  const selectRobot = useCallback(
    (robot_id) => {
      navigate("/dispatch_control/" + fleetId + "/" + robot_id);
    },
    [fleetId, navigate]
  );

  useEffect(() => {
    if (fleetData) {
      fleetData.forEach((fleet) => {
        if (fleet.id === fleetId) {
          setSelectedFleet(fleet);
        }
      });
    }
  }, [fleetData, fleetId]);

  return (
    <React.Fragment>
      <Box sx={{ padding: "1.5rem" }}>
        <Box display={"flex"} style={{ gap: 5 }}>
          <Link
            component="button"
            onClick={() => {
              navigate("/home/" + fleetId);
            }}
            underline="none"
            style={{ color: "white" }}
          >
            Home
          </Link>
          <Typography color="white">/</Typography>
          <Link
            component="button"
            onClick={() => {
              navigate("/dispatch_control/" + fleetId);
            }}
            underline="none"
            style={{ color: "white" }}
          >
            {selectedFleet ? selectedFleet.name : "N/A"}
          </Link>
        </Box>
        <Typography paragraph variant="h4" color={"white"}>
          Select a robot to control
        </Typography>
      </Box>
      <RobotList selectedFleet={selectedFleet} selectRobot={selectRobot} />
    </React.Fragment>
  );
}

export default DispatchControlListPage;
