import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import GuardedRoute from "./login/components/guarded_route";
import LoginPage from "./login/components/login_page";
import DispatchControlRobotPage from "./dispatch_control/components/dispatch_control_robot_page";
import DispatchControlListPage from "./dispatch_control/components/dispatch_control_list_page";
import UserManagementPage from "./user_management/components/user_management_page";
import SettingsPage from "./settings/components/settings_page";
import PageBase from "./shared/components/page_base";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import MonitorPage from "./monitor/components/monitor_page";
import HomePage from "./home/components/home_page";
import MapEditListPage from "./map_edit/components/map_edit_list_page";
import MapEditPage from "./map_edit/components/map_edit_page";
//import ReportPage from "./report/components/report_page";

const theme = createTheme({
  palette: {
    primary: {
      main: "#189FFA",
      contrastText: "white",
    },
    secondary: {
      main: "#889AAC",
      contrastText: "white",
    },
    error: {
      main: "#F94756",
      contrastText: "white",
    },
    warning: {
      main: "#DB7600",
      contrastText: "white",
    },
    info: {
      main: "#FFFFFF",
      contrastText: "#189FFA",
    },
    success: {
      main: "#57BD8A",
      contrastText: "white",
    },
    contrastThreshold: 4.5,
  },
  background_primary: {
    backgroundColor: "#35393F",
    color: "#FFFFFF",
  },
  background_secondary: {
    backgroundColor: "#FFFFFF",
  },
  linear_background_primary: {
    background: "linear-gradient(to bottom right, #303a47, #9dafc1)",
  },
  linear_background_secondary: {
    background: "linear-gradient(to top right, #717e90, #e3e5e8)",
  },
  linear_background_tertiary: {
    background: "linear-gradient(to right, #d9dce0, #e3e7ea)",
  },
  linear_background_quaternary: {
    background: "linear-gradient(to right, #d1d4d8, #d9dce0)",
  },
  components: {
    MuiImageListItemBar: {
      styleOverrides: {
        root: {
          right: "auto",
        },
        titleWrap: {
          paddingLeft: "1rem",
          paddingRight: "1rem",
          paddingTop: "0.2rem",
          paddingBottom: "0.2rem",
          background: "#c6c4c5",
          textTransform: "uppercase",
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />

          <Route
            path="/"
            element={
              <GuardedRoute expectedRole={["user", "manager"]}>
                <PageBase />
              </GuardedRoute>
            }
          >
            <Route path="/" element={<Navigate to="/home" />} />
            <Route
              path="home/:fleetId"
              element={
                <GuardedRoute expectedRole={["user", "manager"]}>
                  <HomePage />
                </GuardedRoute>
              }
            />
            <Route
              path="home"
              element={
                <GuardedRoute expectedRole={["user", "manager"]}>
                  <HomePage />
                </GuardedRoute>
              }
            />

            <Route
              path="monitor/:fleetId"
              element={
                <GuardedRoute expectedRole={["user", "manager"]}>
                  <MonitorPage />
                </GuardedRoute>
              }
            />
            <Route path="monitor" element={<Navigate to="/home" />} />

            <Route
              path="dispatch_control/:fleetId/:robotId"
              element={
                <GuardedRoute expectedRole={["user", "manager"]}>
                  <DispatchControlRobotPage />
                </GuardedRoute>
              }
            />
            <Route
              path="dispatch_control/:fleetId"
              element={
                <GuardedRoute expectedRole={["user", "manager"]}>
                  <DispatchControlListPage />
                </GuardedRoute>
              }
            />
            <Route path="dispatch_control" element={<Navigate to="/home" />} />

            <Route
              path="map_edit/:fleetId/:robotId/:mapId"
              element={
                <GuardedRoute expectedRole={["user", "manager"]}>
                  <MapEditPage />
                </GuardedRoute>
              }
            />
            <Route
              path="map_edit/:fleetId"
              element={
                <GuardedRoute expectedRole={["user", "manager"]}>
                  <MapEditListPage />
                </GuardedRoute>
              }
            />
            <Route path="map_edit" element={<Navigate to="/home" />} />

            <Route
              path="user_management"
              element={
                <GuardedRoute expectedRole={["user", "manager"]}>
                  <UserManagementPage />
                </GuardedRoute>
              }
            />

            <Route
              path="settings"
              element={
                <GuardedRoute expectedRole={["user", "manager"]}>
                  <SettingsPage />
                </GuardedRoute>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
// TODO: <Route path="*" element={<NotFound />} />
/*

<Route
  path="report/:fleetId"
  element={
    <GuardedRoute expectedRole={["user", "manager"]}>
      <ReportPage />
    </GuardedRoute>
  }
/>
<Route path="report" element={<Navigate to="/home" />} />
*/

export default App;
