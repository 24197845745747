import React, { useCallback, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { Joystick } from "react-joystick-component";
import joystickImage from "../../assets/images/joystick.png";
import { useInterval } from "../hooks/useInterval";

function JoystickControl(props) {
  const { socket, localUsername, roomName, currentSpeed } = props;
  const move = useRef(null);

  const handleMove = useCallback(
    (event) => {
      const rad = Math.asin(event.x, event.y);

      move.current = {
        type: "move",
        linear: event.y * currentSpeed,
        angular: -rad * currentSpeed,
      };
    },
    [currentSpeed]
  );

  const handleStop = (event) => {
    move.current = { type: "stop" };
  };

  useInterval(() => {
    if (move.current) {
      socket.current.emit("data", {
        username: localUsername,
        room: roomName,
        data: { type: "teleop", twist: move.current },
      });
      if (move.current.type === "stop") {
        move.current = null;
      }
    }
  }, 100);

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = useCallback(() => {
    move.current = null;
  }, []);

  useEffect(() => {
    window.addEventListener("blur", onBlur);

    return () => {
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  return (
    <React.Fragment>
      <Box
        display="flex"
        sx={{
          backgroundColor: "#bae2fe",
          borderRadius: "50%",
          justifyContent: "center",
          alignItems: "stretch",
          aspectRatio: "1",
          width: "300px",
        }}
      >
        <Box
          display="flex"
          sx={{
            flexGrow: 1,
            backgroundColor: "white",
            borderRadius: "50%",
            justifyContent: "center",
            alignItems: "center",
            aspectRatio: "1",
            margin: "2rem",
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          }}
        >
          <Joystick
            size={80}
            baseColor="white"
            stickImage={joystickImage}
            stickSize={170}
            throttle={100}
            move={handleMove}
            stop={handleStop}
          ></Joystick>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default JoystickControl;
