import React from "react";
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

function Monitor() {
  let { fleetId } = useParams();
  return (
    <React.Fragment>
      <Box sx={{ padding: "1.5rem" }}>
        <Typography paragraph variant="h4" color={"white"}>
          Some monitoring page coming soon... {fleetId}
        </Typography>
      </Box>
    </React.Fragment>
  );
}

export default Monitor;
