import axios from "axios";
import jwt_decode from "jwt-decode";
import { AUTH_URL, SERVER_URL } from "../../constant";
import { refreshToken } from "../../login/actions/login_auth.action";

export function checkToken() {
  const currentToken = localStorage.getItem("access_token");
  if (currentToken) {
    const decodedToken = jwt_decode(currentToken);
    // NOTE: We refresh the token a few minutes before it expires cause I couldn't make that damn thing synchronous and if we refresh it a the last moment, the next API call will fail
    if (decodedToken.exp - 600 < Math.floor(new Date().getTime() / 1000.0)) {
      // console.log("Token is going to expire, refreshing it");
      refreshToken();
    }
  }
  return localStorage.getItem("access_token");
}

export default function get_rest_instance(service) {
  let token;
  switch (service) {
    case "server":
      token = checkToken();
      return axios.create({
        baseURL: SERVER_URL,
        headers: {
          "content-type": "application/json",
          authorization: token != null ? "Bearer " + token : "",
        },
      });
    case "login":
      return axios.create({
        baseURL: AUTH_URL,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      });
    case "logout":
      token = checkToken();
      return axios.create({
        baseURL: AUTH_URL,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          authorization: token != null ? token : "",
        },
      });
    default:
      token = checkToken();
      return axios.create({
        baseURL: AUTH_URL,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          authorization: token != null ? token : "",
        },
      });
  }
}
