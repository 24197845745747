import { Box, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import PanToolIcon from "@mui/icons-material/PanTool";
import GestureIcon from "@mui/icons-material/Gesture";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import AdjustIcon from "@mui/icons-material/Adjust";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DeleteIcon from "@mui/icons-material/Delete";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SaveIcon from "@mui/icons-material/Save";

const MapEditorToolMenu = (props) => {
  const {
    currentTool,
    setCurrentTool,
    setExpanded,
    resetCurrentCoordinates,
    currentId,
    centerCanvas,
    clearShapes,
    resetShapes,
    saveShapes,
  } = props;
  const theme = useTheme();

  return (
    <Box
      display="flex"
      style={theme.background_secondary}
      sx={{
        justifyContent: "space-between",
        padding: "0.5rem",
        gap: "0.5rem",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      <Box
        display="flex"
        style={theme.background_secondary}
        sx={{ padding: "0.5rem", gap: "0.5rem" }}
      >
        <Button
          sx={{ padding: "0.5rem" }}
          variant="text"
          onClick={() => {
            resetCurrentCoordinates();
            centerCanvas();
          }}
        >
          <CenterFocusStrongIcon
            sx={{ marginRight: "0.5rem" }}
          ></CenterFocusStrongIcon>
          Recenter
        </Button>
        <Button
          sx={{ padding: "0.5rem" }}
          variant={currentTool === "panning" ? "contained" : "text"}
          onClick={() => {
            setCurrentTool("panning");
            resetCurrentCoordinates();
          }}
        >
          <PanToolIcon sx={{ marginRight: "0.5rem" }}></PanToolIcon>
          Pan Map
        </Button>
      </Box>
      <Box
        display="flex"
        style={theme.background_secondary}
        sx={{ padding: "0.5rem", gap: "0.5rem" }}
      >
        <Button
          sx={{ padding: "0.5rem" }}
          variant={currentTool === "point" ? "contained" : "text"}
          onClick={() => {
            setCurrentTool("point");
            setExpanded("points");
            resetCurrentCoordinates();
          }}
        >
          <GestureIcon sx={{ marginRight: "0.5rem" }}></GestureIcon>
          Point Tool
        </Button>
        <Button
          sx={{ padding: "0.5rem" }}
          variant={currentTool === "line" ? "contained" : "text"}
          onClick={() => {
            setCurrentTool("line");
            setExpanded("lines");
            resetCurrentCoordinates();
          }}
        >
          <HorizontalRuleIcon
            sx={{ marginRight: "0.5rem" }}
          ></HorizontalRuleIcon>
          Line Tool
        </Button>
        <Button
          sx={{ padding: "0.5rem" }}
          variant={currentTool === "multiline" ? "contained" : "text"}
          onClick={() => {
            currentId.current = crypto.randomUUID();
            setCurrentTool("multiline");
            setExpanded("lines");
            resetCurrentCoordinates();
          }}
        >
          <ShowChartIcon sx={{ marginRight: "0.5rem" }}></ShowChartIcon>
          Multi-Line Tool
        </Button>
        <Button
          sx={{ padding: "0.5rem" }}
          variant={currentTool === "circle" ? "contained" : "text"}
          onClick={() => {
            setCurrentTool("circle");
            setExpanded("circles");
            resetCurrentCoordinates();
          }}
        >
          <AdjustIcon sx={{ marginRight: "0.5rem" }}></AdjustIcon>
          Circle Tool
        </Button>
        <Button
          sx={{ padding: "0.5rem" }}
          variant={currentTool === "rectangle" ? "contained" : "text"}
          onClick={() => {
            setCurrentTool("rectangle");
            setExpanded("rectangles");
            resetCurrentCoordinates();
          }}
        >
          <CheckBoxOutlineBlankIcon
            sx={{ marginRight: "0.5rem" }}
          ></CheckBoxOutlineBlankIcon>
          Rectangle Tool
        </Button>
      </Box>
      <Box
        display="flex"
        style={theme.background_secondary}
        sx={{ padding: "0.5rem", gap: "0.5rem" }}
      >
        <Button
          sx={{ padding: "0.5rem" }}
          variant="contained"
          color="error"
          onClick={() => {
            resetCurrentCoordinates();
            clearShapes();
          }}
        >
          <DeleteIcon sx={{ marginRight: "0.5rem" }}></DeleteIcon>
          Clear
        </Button>
        <Button
          sx={{ padding: "0.5rem" }}
          variant="contained"
          color="error"
          onClick={() => {
            resetCurrentCoordinates();
            resetShapes();
          }}
        >
          <RotateLeftIcon sx={{ marginRight: "0.5rem" }}></RotateLeftIcon>
          Reset
        </Button>
      </Box>
      <Box
        display="flex"
        style={theme.background_secondary}
        sx={{ padding: "0.5rem", gap: "0.5rem" }}
      >
        <Button
          sx={{ padding: "0.5rem" }}
          variant="contained"
          color="success"
          onClick={() => {
            resetCurrentCoordinates();
            saveShapes();
          }}
        >
          <SaveIcon sx={{ marginRight: "0.5rem" }}></SaveIcon>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default MapEditorToolMenu;
