import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  sendControlCoord,
  sendControlPathName,
  sendModeRequest,
} from "../../dispatch_control/actions/control.action";
import { useParams } from "react-router-dom";

const commandData = [
  {
    name: "Move to goal",
    value: "move_to",
    type: ["Wheelchair"],
  },
  {
    name: "Follow path",
    value: "follow_path",
    type: ["Vacuum", "Scrubber"],
  },
];

function AutomaticControl(props) {
  let { robotId } = useParams();
  const { showHeader, mapName, paths, goals, paused, setPaused, robot } = props;

  const theme = useTheme();
  const [selectedCommand, setSelectedCommand] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedPath, setSelectedPath] = useState("");

  const commandChange = (event) => {
    setSelectedCommand(event.target.value);
  };

  const locationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const pathChange = (event) => {
    setSelectedPath(event.target.value);
  };

  useEffect(() => {
    console.log("goals", goals);
    console.log("paths", paths);
    console.log("robot", robot);
  }, [goals, paths, robot]);

  const sendCommand = useCallback(() => {
    console.log("Sending command", selectedCommand);
    if (selectedCommand) {
      if (selectedCommand === "move_to") {
        console.log("selectedLocation", selectedLocation);
        if (selectedLocation) {
          const goal = goals.find((item) => item.id === selectedLocation);
          console.log("goal", goal);
          if (goal) {
            console.log("Sending robot to selected goal");
            sendControlCoord(robotId, goal);
          } else {
            console.error("Couldn't find goal", selectedLocation);
          }
        } else {
          console.error(
            "No location selected for move_to command",
            selectedLocation
          );
        }
      } else if (selectedCommand === "follow_path") {
        const path = paths.find((item) => item.id === selectedPath);

        if (path) {
          sendControlPathName(robotId, mapName, path.name);
          // TODO: This sends a path as a list of goals, only useful for wheelchair, will need to check the type of the robot to either use this function or the one above
          //sendControlPath(robotId, path);
        } else {
          console.error("Couldn't find path", selectedPath);
        }
      } else {
        console.error("Wrong command selected", selectedCommand);
      }
    } else {
      console.error("Command not selected", selectedCommand);
    }
  }, [
    goals,
    mapName,
    paths,
    robotId,
    selectedCommand,
    selectedLocation,
    selectedPath,
  ]);

  const stopRobot = useCallback(() => {
    console.log("Sending mode request [RobotMode.MODE_PAUSED]");
    setPaused(true);
    sendModeRequest(robotId, "RobotMode.MODE_PAUSED");
  }, [robotId, setPaused]);

  const resumeRobot = useCallback(() => {
    console.log("Sending mode request [RobotMode.MODE_MOVING]");
    setPaused(false);
    sendModeRequest(robotId, "RobotMode.MODE_MOVING");
  }, [robotId, setPaused]);

  return (
    <React.Fragment>
      <Card sx={{ flexGrow: 1, borderRadius: "0px" }}>
        <CardHeader
          style={{ display: showHeader ? "" : "none" }}
          title={
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontWeight: "700",
              }}
            >
              Automatic control
            </Typography>
          }
          disableTypography
        ></CardHeader>
        <CardContent>
          <Box
            display="flex"
            sx={{
              flexGrow: 1,
              flexBasis: 0,
              flexDirection: "column",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem",
              gap: "1rem",
            }}
          >
            <Box
              display="flex"
              sx={{
                flexGrow: 1,
                flexBasis: 0,
                gap: "0.5rem",
                alignItems: "center",
              }}
            >
              <Typography sx={{ flexGrow: 1, flexBasis: 0, color: "#808080" }}>
                Select command:
              </Typography>

              <FormControl sx={{ flexGrow: 1, flexBasis: 0 }}>
                <InputLabel id="command-select-label">Command</InputLabel>
                <Select
                  labelId="command-select-label"
                  id="command-select"
                  value={selectedCommand}
                  label="Commands"
                  onChange={commandChange}
                >
                  {commandData.map((commandItem) => {
                    if (commandItem.type.includes(robot.type)) {
                      return (
                        <MenuItem
                          key={commandItem.value}
                          value={commandItem.value}
                        >
                          {commandItem.name}
                        </MenuItem>
                      );
                    } else {
                      return "";
                    }
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box
              display="flex"
              sx={{
                flexGrow: 1,
                flexBasis: 0,
                gap: "0.5rem",
                alignItems: "center",
              }}
            >
              {selectedCommand === "move_to" ? (
                <React.Fragment>
                  <Typography
                    sx={{ flexGrow: 1, flexBasis: 0, color: "#808080" }}
                  >
                    Location:
                  </Typography>
                  <FormControl sx={{ flexGrow: 1, flexBasis: 0 }}>
                    <InputLabel id="location-select-label">Location</InputLabel>
                    <Select
                      labelId="location-select-label"
                      id="location-select"
                      value={selectedLocation}
                      label="Location"
                      onChange={locationChange}
                    >
                      {goals && goals.length !== 0 ? (
                        goals.map((goalItem) => {
                          return (
                            <MenuItem key={goalItem.id} value={goalItem.id}>
                              {goalItem.name}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem disabled key={"no-goal"} value={""}>
                          No goal available
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </React.Fragment>
              ) : selectedCommand === "follow_path" ? (
                <React.Fragment>
                  <Typography
                    sx={{ flexGrow: 1, flexBasis: 0, color: "#808080" }}
                  >
                    Path:
                  </Typography>
                  <FormControl sx={{ flexGrow: 1, flexBasis: 0 }}>
                    <InputLabel id="path-select-label">Path</InputLabel>
                    <Select
                      labelId="path-select-label"
                      id="path-select"
                      value={selectedPath}
                      label="path"
                      onChange={pathChange}
                    >
                      {paths && paths.length !== 0 ? (
                        paths.map((pathItem) => {
                          return (
                            <MenuItem key={pathItem.id} value={pathItem.id}>
                              {pathItem.name}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem disabled key={"no-path"} value={""}>
                          No path available
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </React.Fragment>
              ) : (
                ""
              )}
            </Box>
            <Box
              display="flex"
              sx={{
                flexGrow: 1,
                flexBasis: 0,
                gap: "0.5rem",
                alignItems: "center",
                display: "none",
              }}
            >
              <Typography
                sx={{
                  marginLeft: "3rem",
                  color: "#808080",
                }}
              >
                or
              </Typography>
            </Box>
            <Box
              display="flex"
              sx={{
                flexGrow: 1,
                flexBasis: 0,
                gap: "0.5rem",
                alignItems: "center",
                display: "none",
              }}
            >
              <Typography sx={{ flexGrow: 1, flexBasis: 0, color: "#808080" }}>
                Set location pin:
              </Typography>

              <Box sx={{ flexGrow: 1, flexBasis: 0 }}>
                <IconButton
                  color="primary"
                  aria-label="Set location pin"
                  sx={{ padding: 0 }}
                >
                  <LocationOnIcon sx={{ width: "3rem", height: "3rem" }} />
                </IconButton>
              </Box>
            </Box>

            <Box
              display="flex"
              sx={{ flexGrow: 1, flexBasis: 0, gap: "0.5rem" }}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<PlayArrowIcon />}
                sx={{ flexGrow: 1, flexBasis: 0 }}
                size="large"
                onClick={sendCommand}
              >
                Send command
              </Button>

              {paused ? (
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<PlayArrowIcon />}
                  sx={{ flexGrow: 1, flexBasis: 0 }}
                  size="large"
                  onClick={resumeRobot}
                >
                  Resume robot
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<StopIcon />}
                  sx={{ flexGrow: 1, flexBasis: 0 }}
                  size="large"
                  onClick={stopRobot}
                >
                  Pause robot
                </Button>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default AutomaticControl;
