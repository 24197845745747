import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import VideoView from "./video_view";
import "flickity/css/flickity.css";
import "./custom_flickity.css";

function SlideshowVideoView(props) {
  const {
    robot,
    centerImage,
    bottomImage,
    leftImage,
    rightImage,
    topImage,
    wideImage,
    changeCameras,
  } = props;

  const carousel = useRef(null);
  const flickity_object = useRef(null);

  useEffect(() => {
    initFlickity();
    return () => {
      flickity_object.current.off("change", changeCameras);
    };
  }, []);

  async function initFlickity() {
    if (typeof window !== "undefined" && carousel.current) {
      const Flickity = (await import("flickity")).default;
      flickity_object.current = new Flickity(carousel.current, {
        prevNextButtons: false,
      });
      flickity_object.current.on("change", changeCameras);
    }
  }

  return (
    <React.Fragment>
      <Box ref={carousel}>
        {robot.cameras.left_camera_active ? (
          <VideoView
            key="left_camera"
            sx={{ flexGrow: 1, flexBasis: 0 }}
            title="Left camera"
            reverse={robot.cameras.left_camera_reverse}
            image={leftImage}
          ></VideoView>
        ) : (
          ""
        )}

        {robot.cameras.bottom_camera_active ? (
          <VideoView
            key="bottom_camera"
            sx={{ flexGrow: 1, flexBasis: 0 }}
            title="Bottom camera"
            reverse={robot.cameras.bottom_camera_reverse}
            image={bottomImage}
          ></VideoView>
        ) : (
          ""
        )}

        {robot.cameras.center_camera_active ? (
          <VideoView
            key="center_camera"
            sx={{ flexGrow: 1, flexBasis: 0 }}
            title="Center camera"
            reverse={robot.cameras.center_camera_reverse}
            image={centerImage}
          ></VideoView>
        ) : (
          ""
        )}

        {robot.cameras.right_camera_active ? (
          <VideoView
            key="right_camera"
            sx={{ flexGrow: 1, flexBasis: 0 }}
            title="Right camera"
            reverse={robot.cameras.right_camera_reverse}
            image={rightImage}
          ></VideoView>
        ) : (
          ""
        )}

        {robot.cameras.top_camera_active ? (
          <VideoView
            key="top_camera"
            sx={{ flexGrow: 1, flexBasis: 0 }}
            title="Camera"
            reverse={robot.cameras.top_camera_reverse}
            image={topImage}
          ></VideoView>
        ) : (
          ""
        )}

        {robot.cameras.wide_camera_active ? (
          <VideoView
            key="wide_camera"
            sx={{ flexGrow: 1, flexBasis: 0 }}
            title="Top camera"
            reverse={robot.cameras.wide_camera_reverse}
            image={wideImage}
          ></VideoView>
        ) : (
          ""
        )}
      </Box>
    </React.Fragment>
  );
}

export default SlideshowVideoView;
