import React from "react";
import { ImageListItem, Typography, ImageListItemBar } from "@mui/material";
import noCameraImage from "../../assets/images/no_camera.jpg";

function VideoView(props) {
  const { title, reverse, image } = props;

  return (
    <React.Fragment>
      <ImageListItem>
        <img
          src={image ? image : noCameraImage}
          alt={title}
          style={{
            maxHeight: "280px",
            transform: image && reverse ? "scaleX(-1) scaleY(-1)" : "",
          }}
        />
        <ImageListItemBar
          title={<Typography color="black">{title}</Typography>}
          position="top"
        />
      </ImageListItem>
    </React.Fragment>
  );
}

export default VideoView;
