import React from "react";
import { Button, Grid, Typography, useTheme } from "@mui/material";
import no_map_image from "../../assets/images/no_image_placeholder.jpg";

function MapList(props) {
  const { selectMap, robotId, maps } = props;
  const theme = useTheme();

  return (
    <React.Fragment>
      {maps.length > 0 ? (
        maps.map((map) => (
          <Button
            key={robotId + "-map-" + map.id}
            style={theme.linear_background_quaternary}
            sx={{
              padding: "1rem",
              marginLeft: "4rem",
              borderRadius: 0,
              textAlign: "left",
              textTransform: "none",
              color: "black",
            }}
            onClick={() => {
              selectMap(robotId, map.id);
            }}
          >
            <Grid container>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>{map.name}</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={
                    "image" in map
                      ? "data:image/jpeg;base64," + map.image
                      : no_map_image
                  }
                  alt="Robot Map"
                  style={{ maxWidth: "100%", maxHeight: "100px" }}
                ></img>
              </Grid>
            </Grid>
          </Button>
        ))
      ) : (
        <Button
          key={robotId + "-no-map"}
          style={theme.linear_background_quaternary}
          sx={{
            padding: "1rem",
            marginLeft: "2rem",
            borderRadius: 0,
            textAlign: "left",
            textTransform: "none",
            color: "black",
          }}
        >
          No map available for this robot
        </Button>
      )}
    </React.Fragment>
  );
}

export default MapList;
