import React from "react";
import UserManagement from "./user_management";

function UserManagementPage() {
  document.title = "User Management | Cyberworks Robotics Fleet Management";
  return (
    <React.Fragment>
      <UserManagement />
    </React.Fragment>
  );
}

export default UserManagementPage;
