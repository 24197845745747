import React from "react";
import Grid from "@mui/material/Grid";
import LoginCard from "./login_card";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material";

function LoginPage() {
  document.title = "Login | Cyberworks Robotics Fleet Management";
  document.querySelector("body").style.backgroundColor = "#FFFFFF";
  const theme = useTheme();

  return (
    <React.Fragment>
      <Box
        style={theme.linear_background_secondary}
        sx={{
          display: "flex",
          minHeight: "100vh",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          spacing={0}
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "45em" }}
        >
          <Grid item xs={10} sm={10} md={6} lg={4}>
            <LoginCard />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default LoginPage;
