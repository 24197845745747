import React, { useContext, useEffect, useState } from "react";
import { Box, Link, Typography } from "@mui/material";
import { FleetContext } from "../../shared/components/page_base";
import { useNavigate, useParams } from "react-router-dom";
import { getMapById } from "../actions/map_edit.action";
import MapEditor from "./map_editor";

function MapEditPage() {
  const navigate = useNavigate();
  const { fleetData } = useContext(FleetContext);
  let { fleetId, robotId, mapId } = useParams();
  const [map, setMap] = useState({});
  const [selectedFleet, setSelectedFleet] = useState(null);
  const [selectedRobot, setSelectedRobot] = useState(null);

  useEffect(() => {
    getMapById(mapId)
      .then(function (data) {
        console.log("Got map by id", mapId, data);
        setMap(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [mapId]);

  useEffect(() => {
    if (fleetData) {
      fleetData.forEach((fleet) => {
        if (fleet.id === fleetId) {
          setSelectedFleet(fleet);
          setSelectedRobot(
            fleet.robots.find((item) => {
              return item.id === robotId;
            })
          );
        }
      });
    }
  }, [fleetData, fleetId, robotId]);

  return (
    <React.Fragment>
      <Box sx={{ padding: "1.5rem" }}>
        <Box display={"flex"} style={{ gap: 5 }}>
          <Link
            component="button"
            onClick={() => {
              navigate("/home/" + fleetId);
            }}
            underline="none"
            style={{ color: "white" }}
          >
            Home
          </Link>
          <Typography color="white">/</Typography>
          <Link
            component="button"
            onClick={() => {
              navigate("/map_edit/" + fleetId);
            }}
            underline="none"
            style={{ color: "white" }}
          >
            {selectedFleet ? selectedFleet.name : "N/A"}
          </Link>
          <Typography color="white">/</Typography>
          <Link
            component="button"
            underline="none"
            style={{ color: "white" }}
            noWrap
          >
            {selectedRobot ? selectedRobot.name : "N/A"}
          </Link>
        </Box>
        <Typography paragraph variant="h4" color={"white"}>
          {map
            ? 'Editing map "' + map.name + '"'
            : 'Loading map "' + map.name + '"...'}
        </Typography>
      </Box>
      <MapEditor fleetId={fleetId} robotId={robotId} map={map}></MapEditor>
    </React.Fragment>
  );
}

export default MapEditPage;
