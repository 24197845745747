import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from "@mui/material";
import JoystickControl from "./joystick_control";
import KeyboardControl from "./keyboard_control";
import SpeedSlider from "./speed_slider";

function ManualControl(props) {
  const { showHeader, socket, localUsername, roomName } = props;
  const [selectedControl, setSelectedControl] = useState("joystick");
  const [currentSpeed, setCurrentSpeed] = useState(0.5);

  const theme = useTheme();

  return (
    <React.Fragment>
      <Card sx={{ flexGrow: 1, borderRadius: "0px" }}>
        <CardHeader
          style={{ display: showHeader ? "" : "none" }}
          title={
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontWeight: "700",
              }}
            >
              Manual control
            </Typography>
          }
          action={
            <Box display="flex">
              <Button
                onClick={() => {
                  setSelectedControl("keyboard");
                }}
                variant={
                  selectedControl === "keyboard" ? "contained" : "outlined"
                }
                color="primary"
                sx={[
                  selectedControl !== "keyboard"
                    ? {
                        color: "black",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                        borderLeft: "1px solid black",
                        "&:hover": {
                          backgroundColor: "#DDDDDD",
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                          borderLeft: "1px solid black",
                        },
                      }
                    : {},
                  {
                    borderRadius: "4px 0 0 4px",
                    textTransform: "none",
                    borderRight: "none",
                    "&:hover": {
                      borderRight: "none",
                    },
                  },
                ]}
              >
                Keypad
              </Button>
              <Button
                onClick={() => {
                  setSelectedControl("joystick");
                }}
                variant={
                  selectedControl === "joystick" ? "contained" : "outlined"
                }
                color="primary"
                sx={[
                  selectedControl !== "joystick"
                    ? {
                        color: "black",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                        borderRight: "1px solid black",
                        "&:hover": {
                          backgroundColor: "#DDDDDD",
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                        },
                      }
                    : {},
                  {
                    borderRadius: "0 4px 4px 0",
                    textTransform: "none",
                    borderLeft: "none",
                    "&:hover": {
                      borderLeft: "none",
                    },
                  },
                ]}
              >
                Joystick
              </Button>
            </Box>
          }
          disableTypography
        ></CardHeader>
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {selectedControl === "joystick" ? (
            <JoystickControl
              socket={socket}
              localUsername={localUsername}
              roomName={roomName}
              currentSpeed={currentSpeed}
            />
          ) : (
            <KeyboardControl
              socket={socket}
              localUsername={localUsername}
              roomName={roomName}
              currentSpeed={currentSpeed}
            />
          )}
          <SpeedSlider
            currentSpeed={currentSpeed}
            setCurrentSpeed={setCurrentSpeed}
          />
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default ManualControl;
