import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  getUserFullName,
  logout,
  refreshToken,
} from "../../login/actions/login_auth.action";
import { useNavigate } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HomeIcon from "@mui/icons-material/Home";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";
import SettingsRemoteOutlinedIcon from "@mui/icons-material/SettingsRemoteOutlined";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import PeopleIcon from "@mui/icons-material/People";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import { Outlet } from "react-router-dom";
import cyberworks_logo from "../../assets/images/cyberworks_logo.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MapIcon from "@mui/icons-material/Map";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { useMediaQuery } from "../hooks/mediaQuery";
import { useInterval } from "../hooks/useInterval";
import { getFleetData } from "../actions/page_base.action";
import { io } from "socket.io-client";
import { WEBSOCKET_URL } from "../../constant";
import { checkToken } from "../services/common.service";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  background: "linear-gradient(to top, #717e90, #e3e5e8)",
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: "linear-gradient(to top, #717e90, #e3e5e8)",
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const FleetContext = React.createContext(null);

export default function PageBase() {
  let { fleetId, robotId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const socket = useRef(null);

  const [anchorElUser, setAnchorElUser] = useState(null);
  const userName = useMemo(getUserFullName, []);
  const [fleetData, setFleetData] = useState([]);
  const [robotStates, setRobotStates] = useState(new Map());

  const isMobile = useMediaQuery("(max-width: 768px)");

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (key) => {
    setAnchorElUser(null);
    if (key === "Logout") {
      logoutEvent();
    }
  };

  const logoutEvent = useCallback(() => {
    logout(navigate);
  }, [navigate]);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuClick = useCallback(
    (url) => {
      navigate(url);
    },
    [navigate]
  );

  const onFleetState = useCallback(
    (data) => {
      data["robot_states"].forEach((robot_state) => {
        if (robotStates.has(robot_state["id"])) {
          setRobotStates(
            robotStates.set(robot_state["id"], {
              fleet_id: data["id"],
              name: robot_state["name"],
              type: robot_state["type"],
              task_id: robot_state["task_id"],
              mode: robot_state["mode"],
              battery_percent: robot_state["battery_percent"],
              location: robot_state["location"],
              path: robot_state["path"],
              error_string: robot_state["error_string"],
              scan_data: robot_state["scan_data"],
            })
          );
        } else {
          setRobotStates(
            new Map(
              robotStates.set(robot_state["id"], {
                fleet_id: data["id"],
                name: robot_state["name"],
                type: robot_state["type"],
                task_id: robot_state["task_id"],
                mode: robot_state["mode"],
                battery_percent: robot_state["battery_percent"],
                location: robot_state["location"],
                path: robot_state["path"],
                error_string: robot_state["error_string"],
                scan_data: robot_state["scan_data"],
              })
            )
          );
        }
      });
    },
    [robotStates]
  );

  /// TODO: Add color and style for status, battery and active columns
  useEffect(() => {
    getFleetData()
      .then(function (data) {
        setFleetData(data.fleets);
        // Subscribe to the data for each fleet associated to this user
        data.fleets.forEach((fleet) => {
          socket.current.emit("fleet_join", {
            fleet_id: fleet["id"],
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });

    // Setup the websocket to get the robot state
    socket.current = io(WEBSOCKET_URL, {
      autoConnect: false,
      extraHeaders: {
        Authorization: "Bearer " + checkToken(),
      },
      path: "/server.socket.io",
    });
    socket.current.on("fleet_state", onFleetState);

    socket.current.connect();

    return function cleanup() {
      socket.current.off("fleet_state", onFleetState);
      socket.current.disconnect();
    };
  }, []);

  /// Refresh the token every 4 minutes
  /// TODO: Temporary solution since we don't have any calls to the backend to refresh the token, we automatically refresh it every 4 minutes
  useInterval(() => {
    refreshToken();
  }, 240000);

  const menuList = [
    [
      {
        text: "Home",
        icon: <HomeOutlinedIcon />,
        selected_icon: <HomeIcon color="primary" />,
        url: fleetId ? "/home/" + fleetId : "/home",
        disabled: false,
      },
    ],
    [
      /*{
        text: "Monitor",
        icon: <MapOutlinedIcon />,
        selected_icon: <MapIcon color="primary" />,
        url: fleetId ? "/monitor/" + fleetId : "/monitor",
        disabled: !fleetId,
      },*/
      {
        text: "Dispatch Control",
        icon: <SettingsRemoteOutlinedIcon />,
        selected_icon: <SettingsRemoteIcon color="primary" />,
        url: robotId
          ? "/dispatch_control/" + fleetId + "/" + robotId
          : fleetId
          ? "/dispatch_control/" + fleetId
          : "/dispatch_control",
        disabled: !fleetId,
      },
      {
        text: "Edit Map",
        icon: <EditLocationOutlinedIcon />,
        selected_icon: <EditLocationAltIcon color="primary" />,
        url: fleetId ? "/map_edit/" + fleetId : "/map_edit",
        disabled: !fleetId,
      } /*
      {
        text: "See Fleet Report",
        icon: <QueryStatsOutlinedIcon />,
        selected_icon: <QueryStatsIcon color="primary" />,
        url: fleetId ? "/report/" + fleetId : "/report",
        disabled: !fleetId,
      },*/,
    ] /*
    [
      {
        text: "Account Management",
        icon: <PeopleAltOutlinedIcon />,
        selected_icon: <PeopleIcon color="primary" />,
        url: "/user_management",
        disabled: false,
      },
      {
        text: "Settings",
        icon: <SettingsOutlinedIcon />,
        selected_icon: <SettingsIcon color="primary" />,
        url: "/settings",
        disabled: false,
      },
    ],*/,
  ];

  const settings = ["Logout"];

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <FleetContext.Provider
        value={{ fleetData: fleetData, robotStates: robotStates }}
      >
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          style={theme.background_secondary}
        >
          <Toolbar>
            <IconButton
              color="primary"
              aria-label="open drawer"
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                display: isMobile ? "none" : "",
              }}
            >
              {open ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
            <img src={cyberworks_logo} alt="Logo" />
            <Tooltip title="Open settings" sx={{ marginLeft: "auto" }}>
              <Button onClick={handleOpenUserMenu}>
                <AccountCircleIcon />
                <Typography
                  variant="subtitle2"
                  sx={{ color: "black", marginTop: "3px" }}
                  px={1}
                >
                  {userName}
                </Typography>
                <KeyboardArrowDownIcon color="secondary" />
              </Button>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={() => {
                handleCloseUserMenu("");
              }}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => {
                    handleCloseUserMenu(setting);
                  }}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          style={theme.linear_background_secondary}
          sx={{
            display: isMobile ? "none" : "",
          }}
        >
          <Toolbar />
          {menuList.map((submenu, index) => {
            return [
              <Divider
                key={"divider_index_" + index}
                sx={{ visible: index === 0 ? "none" : "" }}
              />,
              <List key={"menu_index_" + index}>
                {submenu.map((elem) => (
                  <ListItem
                    key={elem.text}
                    disablePadding
                    sx={{
                      display: "block",
                      background: location.pathname.startsWith(elem.url)
                        ? "linear-gradient(to right, #FFFFFF, #ccd0d5)"
                        : "none",
                      boxShadow: location.pathname.startsWith(elem.url)
                        ? "0 2px 0 0 #ccd0d5"
                        : "none",
                      marginTop: 1,
                      marginBottom: 1,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      disabled={elem.disabled}
                      onClick={() => {
                        handleMenuClick(elem.url);
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {location.pathname.startsWith(elem.url)
                          ? elem.selected_icon
                          : elem.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={elem.text}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>,
            ];
          })}
        </Drawer>
        <Box
          component="main"
          display="flex"
          sx={{ flexDirection: "column", flexGrow: 1 }}
          style={theme.linear_background_primary}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </FleetContext.Provider>
    </Box>
  );
}
