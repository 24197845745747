import React, { useContext, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { FleetContext } from "./page_base";
import { useInterval } from "../hooks/useInterval";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import robotImage from "../../assets/images/robot_dot.png";

function MapView(props) {
  const { map } = props;
  let { fleetId, robotId } = useParams();
  const theme = useTheme();
  const [mapImage, setMapImage] = useState(null);
  const { robotStates } = useContext(FleetContext);
  const resolution = useRef(0.05);
  const originX = useRef(0.0);
  const originY = useRef(0.0);
  const initMapImage = useRef(false);
  const [robotPoseX, setRobotPoseX] = useState(0);
  const [robotPoseY, setRobotPoseY] = useState(0);
  const imageRef = useRef(null);
  const originalWidth = useRef(0);

  useInterval(() => {
    // console.log("fleetData", fleetData);
    // console.log("robotStates", robotStates);
    if (robotStates && robotStates.has(robotId)) {
      if (!initMapImage.current) {
        //console.log("Initializing map image");
        if (map) {
          setMapImage(map.image);
          var image = new Image();
          image.onload = function () {
            originalWidth.current = image.width;
            resolution.current = map.resolution;
            originX.current = -map.origin_x / resolution.current;
            originY.current = image.height + map.origin_y / resolution.current;
            initMapImage.current = true;
          };
          image.src = "data:image/jpeg;base64," + map.image;
        }
      } else {
        const imageRatio = imageRef.current.offsetWidth / originalWidth.current;
        const X =
          (robotStates.get(robotId).location.x / resolution.current +
            originX.current) *
          imageRatio;
        const Y =
          (-robotStates.get(robotId).location.y / resolution.current +
            originY.current) *
          imageRatio;
        setRobotPoseX(X);
        setRobotPoseY(Y);
      }
    }
  }, 1000);

  return (
    <React.Fragment>
      <Box
        display="flex"
        sx={{ flexGrow: 1 }}
        style={theme.background_secondary}
      >
        <TransformWrapper initialScale={1} limitToBounds={false}>
          <TransformComponent wrapperStyle={{ flexGrow: 1, height: "auto" }}>
            <div style={{ position: "relative", top: 0, left: 0 }}>
              <img
                src={mapImage ? "data:image/jpeg;base64," + mapImage : ""}
                alt="Loading robot map..."
                ref={imageRef}
                style={{
                  imageRendering: "pixelated",
                  position: "relative",
                  top: 0,
                  left: 0,
                  maxWidth: "100%",
                }}
              />
              <img
                src={robotImage}
                alt="Robot"
                style={{
                  display: mapImage ? "" : "none",
                  position: "absolute",
                  top: robotPoseY - 7,
                  left: robotPoseX - 7,
                  width: "15px",
                  height: "15px",
                }}
              />
            </div>
          </TransformComponent>
        </TransformWrapper>
      </Box>
    </React.Fragment>
  );
}

export default MapView;
