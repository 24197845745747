import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import SettingsRemoteOutlinedIcon from "@mui/icons-material/SettingsRemoteOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { FleetContext } from "../../shared/components/page_base";
import no_map_image from "../../assets/images/no_image_placeholder.jpg";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";

function Home() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [menuAnchors, setMenuAnchors] = useState({});
  // eslint-disable-next-line no-unused-vars
  const { fleetData } = useContext(FleetContext);
  let { fleetId } = useParams();

  const handleMenuChange = useCallback(
    (index, target) => {
      setMenuAnchors({
        ...menuAnchors,
        [index]: target,
      });
    },
    [menuAnchors]
  );

  const handleOpenUserMenu = (index, event) => {
    handleMenuChange(index, event.currentTarget);
  };

  const handleCloseUserMenu = useCallback(
    (index, id, action) => {
      handleMenuChange(index, null);
      if (action && id) {
        navigate("/" + action + "/" + id);
      }
    },
    [navigate, handleMenuChange]
  );

  const fleet_menu = [
    /*{
      text: "Monitor",
      icon: <MapOutlinedIcon />,
      url: "monitor",
    },*/
    {
      text: "Dispatch Control",
      icon: <SettingsRemoteOutlinedIcon />,
      url: "dispatch_control",
    },
    {
      text: "Edit Map",
      icon: <EditLocationOutlinedIcon />,
      url: "map_edit",
    } /*,
    {
      text: "See Fleet Report",
      icon: <QueryStatsOutlinedIcon />,
      url: "report",
    },*/,
  ];

  //useEffect(() => {
  //  console.log("Home fleet data", fleetData);
  //}, [fleetData]);

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1, padding: "1.5rem" }}>
        <Typography variant="h4" color={"white"}>
          FLEET MANAGEMENT
        </Typography>
        {fleetData && fleetData.length !== 0
          ? fleetData.map((fleet, fleetIndex) => (
              <Card
                key={fleet.id + "-card"}
                sx={{
                  marginTop: 3,
                  marginBottom: 3,
                  outline:
                    fleetId === fleet.id
                      ? "5px solid " + theme.palette.primary.main
                      : "",
                }}
                style={theme.linear_background_tertiary}
                onClick={() => {
                  navigate("/home/" + fleet.id);
                }}
              >
                <CardHeader
                  title={
                    <Box>
                      <Typography variant="h4">{fleet.name}</Typography>
                      <Typography color="#6d6f70" variant="body1">
                        {fleet.address}
                      </Typography>
                    </Box>
                  }
                  action={
                    <Tooltip title="Fleet settings" sx={{ marginLeft: "auto" }}>
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          handleOpenUserMenu(fleetIndex, event);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Tooltip>
                  }
                ></CardHeader>
                <CardContent>
                  <Menu
                    sx={{ mt: "45px" }}
                    anchorEl={menuAnchors[fleetIndex]}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(menuAnchors[fleetIndex])}
                    onClose={() => {
                      handleCloseUserMenu(fleetIndex, "", "");
                    }}
                  >
                    {fleet_menu.map((fleet_menu_item) => (
                      <MenuItem
                        key={fleet_menu_item.url}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleCloseUserMenu(
                            fleetIndex,
                            fleet.id,
                            fleet_menu_item.url
                          );
                        }}
                      >
                        <ListItemIcon>{fleet_menu_item.icon}</ListItemIcon>
                        <ListItemText>{fleet_menu_item.text}</ListItemText>
                      </MenuItem>
                    ))}
                  </Menu>
                  <Grid container alignItems="stretch">
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      {fleet.data.map((itemData, index) => (
                        <Box
                          key={fleet.id + "-" + index}
                          flexDirection="row"
                          sx={{
                            display: "flex",
                            marginTop: 2,
                            marginBottom: 2,
                            flexGrow: 1,
                          }}
                        >
                          <Typography
                            color="#6d6f70"
                            variant="body1"
                            sx={{ marginRight: 1 }}
                          >
                            {itemData.name}:
                          </Typography>
                          <Typography variant="body1">
                            {itemData.value}
                          </Typography>
                        </Box>
                      ))}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <img
                        src={
                          "image" in fleet.map
                            ? "data:image/jpeg;base64," + fleet.map.image
                            : no_map_image
                        }
                        alt="Fleet Map"
                        style={{ maxWidth: "100%", maxHeight: "250px" }}
                      ></img>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))
          : "No fleet available, try refreshing the page"}
      </Box>
    </React.Fragment>
  );
}

export default Home;
