import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { FleetContext } from "../../shared/components/page_base";
import { useInterval } from "../../shared/hooks/useInterval";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import ShapesList from "./shapes_list";
import AlertDialog from "../../shared/components/alert_dialog";
import {
  getObstacleLayer,
  saveObstacleLayer,
} from "../actions/map_edit.action";
import MapEditorToolMenu from "./map_editor_tool_menu";
import robotImagePng from "../../assets/images/robot_dot2.png";

const MapEditor = (props) => {
  const { fleetId, robotId, map } = props;
  const { robotStates } = useContext(FleetContext);
  const theme = useTheme();
  const canvasRef = useRef(null);
  const initialized = useRef(false);
  const [currentTool, setCurrentTool] = useState("panning");
  const shapes = useRef({
    points: new Map(),
    circles: new Map(),
    lines: new Map(),
    rectangles: new Map(),
  });
  const zoom = useRef(1);
  const mouseDown = useRef(false);
  const panStart = useRef({ x: 0, y: 0 });
  const delta = useRef({ x: 0, y: 0 });
  const currentId = useRef(null);
  const middleButtonPan = useRef(false);
  const highlightedId = useRef(null);
  const [openClearDialog, setOpenClearDialog] = useState(false);
  const currentDrawingCoordinates = useRef(null);
  const tmpDrawingCoordinates = useRef(null);
  const currentThickness = useRef(1);
  const [shapeUpdate, setShapeUpdate] = useState(false);
  const currentDrawingCoordinates2 = useRef(null);
  const mapImage = useRef(new Image());
  const robotImage = useRef(new Image());
  const robotImageScale = useRef(1.0);
  const imageInitialized = useRef(false);
  const [expanded, setExpanded] = React.useState("");
  const robotPose = useRef({ x: null, y: null, yaw: null });
  const robotScanData = useRef([]);
  const initialObstacles = useRef(null);

  const distanceFromPointToLine = (point1, point2, point3) => {
    const numerator = Math.abs(
      (point2.y - point1.y) * point3.x -
        (point2.x - point1.x) * point3.y +
        point2.x * point1.y -
        point2.y * point1.x
    );
    const denominator = Math.sqrt(
      Math.pow(point2.y - point1.y, 2) + Math.pow(point2.x - point1.x, 2)
    );

    return numerator / denominator;
  };

  const pointSideOfLine = (point1, point2, point3) => {
    /// Calculate vectors
    let vector1 = { x: point2.x - point1.x, y: point2.y - point1.y };
    let vector2 = { x: point3.x - point1.x, y: point3.y - point1.y };

    /// Calculate cross product
    let crossProduct = vector1.x * vector2.y - vector1.y * vector2.x;

    /// Determine the side based on the sign of the cross product
    return crossProduct >= 0 ? -1 : 1;
  };

  /// Make a rectangle out of 3 points
  const createRectangleFromSegmentAndPoint = useCallback(
    (point1, point2, point3) => {
      /// Get the length of the other side of the rectangle
      const distance = distanceFromPointToLine(point1, point2, point3);

      /// Calculate the direction vector from A to B
      let directionVector = { x: point2.x - point1.x, y: point2.y - point1.y };

      /// Normalize the direction vector
      let length = Math.sqrt(directionVector.x ** 2 + directionVector.y ** 2);
      directionVector.x /= length;
      directionVector.y /= length;

      /// Get which side of the line the point is on
      const side = pointSideOfLine(point1, point2, point3);

      /// Rotate the normalized direction vector by 90 degrees depending on which side of the line (point1, point2) is point3
      let perpendicularVector = {
        x: side * directionVector.y,
        y: side * -directionVector.x,
      };

      /// Scale the perpendicular vector by the given distance to find C and D
      let corner3 = {
        x: point2.x + distance * perpendicularVector.x,
        y: point2.y + distance * perpendicularVector.y,
      };
      let corner4 = {
        x: point1.x + distance * perpendicularVector.x,
        y: point1.y + distance * perpendicularVector.y,
      };

      /// We got our rectangle :D
      return {
        corner1: point1,
        corner2: point2,
        corner3: corner3,
        corner4: corner4,
      };
    },
    []
  );

  const drawElements = useCallback(() => {
    if (!imageInitialized.current) {
      return;
    }

    const ctx = canvasRef.current.getContext("2d");

    /// Clear canvas
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    /// Disable smoothing for pixelation
    ctx.imageSmoothingEnabled = false;

    /// Draw the image
    ctx.drawImage(
      mapImage.current,
      delta.current.x,
      delta.current.y,
      mapImage.current.width * zoom.current,
      mapImage.current.height * zoom.current
    );

    /// Draw the robot
    if (robotPose.current.x && robotPose.current.y && robotPose.current.yaw) {
      ctx.translate(
        delta.current.x + robotPose.current.x * zoom.current,
        delta.current.y + robotPose.current.y * zoom.current
      );
      ctx.rotate(robotPose.current.yaw);
      ctx.translate(
        -(delta.current.x + robotPose.current.x * zoom.current),
        -(delta.current.y + robotPose.current.y * zoom.current)
      );
      const robotX =
        delta.current.x +
        robotPose.current.x * zoom.current -
        (robotImage.current.width * zoom.current * robotImageScale.current) / 2;
      const robotY =
        delta.current.y +
        robotPose.current.y * zoom.current -
        (robotImage.current.height * zoom.current * robotImageScale.current) /
          2;
      ctx.drawImage(
        robotImage.current,
        robotX,
        robotY,
        robotImage.current.width * zoom.current * robotImageScale.current,
        robotImage.current.height * zoom.current * robotImageScale.current
      );

      ///restore the state of canvas
      ctx.restore();

      /// Reset transformation matrix to the identity matrix
      ctx.setTransform(1, 0, 0, 1, 0, 0);
    }

    /// Draw the robot laser scan
    if (robotScanData.current) {
      ctx.fillStyle = "#00a342";
      robotScanData.current.forEach((point) => {
        const scanX =
          delta.current.x +
          ((point[0] - map.origin_x) / map.resolution) * zoom.current;
        const scanY =
          delta.current.y +
          (mapImage.current.height -
            (point[1] - map.origin_y) / map.resolution) *
            zoom.current;
        ctx.fillRect(scanX, scanY, zoom.current, zoom.current);
      });
    }

    /// Draw points
    shapes.current.points.forEach((points, id) => {
      if (highlightedId.current === "points" || highlightedId.current === id) {
        ctx.fillStyle = "blue";
      } else {
        ctx.fillStyle = "red";
      }
      points.forEach((point) => {
        ctx.fillRect(
          delta.current.x + point.x * zoom.current,
          delta.current.y + point.y * zoom.current,
          zoom.current,
          zoom.current
        );
      });
    });

    /// Draw lines
    shapes.current.lines.forEach((line, id) => {
      if (highlightedId.current === "lines" || highlightedId.current === id) {
        ctx.strokeStyle = "blue";
      } else {
        ctx.strokeStyle = "red";
      }
      ctx.beginPath();
      for (let i = 0; i < line.points.length; i++) {
        if (i === 0) {
          ctx.moveTo(
            delta.current.x + line.points[i].x * zoom.current,
            delta.current.y + line.points[i].y * zoom.current
          );
        } else {
          ctx.lineTo(
            delta.current.x + line.points[i].x * zoom.current,
            delta.current.y + line.points[i].y * zoom.current
          );
        }
      }
      ctx.lineWidth = line.thickness * zoom.current;
      ctx.stroke();
    });

    /// Draw temporary line
    if (
      (currentTool === "line" || currentTool === "multiline") &&
      currentDrawingCoordinates.current &&
      tmpDrawingCoordinates.current
    ) {
      ctx.beginPath();
      ctx.moveTo(
        delta.current.x + currentDrawingCoordinates.current.x * zoom.current,
        delta.current.y + currentDrawingCoordinates.current.y * zoom.current
      );
      ctx.lineTo(
        delta.current.x + tmpDrawingCoordinates.current.x * zoom.current,
        delta.current.y + tmpDrawingCoordinates.current.y * zoom.current
      );
      ctx.lineWidth = currentThickness.current * zoom.current;
      ctx.strokeStyle = "blue";
      ctx.stroke();
    }

    /// Draw circles
    shapes.current.circles.forEach((circle, id) => {
      if (highlightedId.current === "circles" || highlightedId.current === id) {
        ctx.strokeStyle = "blue";
      } else {
        ctx.strokeStyle = "red";
      }
      ctx.beginPath();
      ctx.arc(
        delta.current.x + circle.origin.x * zoom.current,
        delta.current.y + circle.origin.y * zoom.current,
        circle.radius * zoom.current,
        0,
        2 * Math.PI
      );
      ctx.lineWidth = circle.thickness * zoom.current;
      ctx.stroke();
    });

    /// Draw temporary circle
    if (
      currentTool === "circle" &&
      currentDrawingCoordinates.current &&
      tmpDrawingCoordinates.current
    ) {
      /// Draw a dot in the middle of the circle just as a visual reference
      ctx.fillRect(
        delta.current.x +
          (currentDrawingCoordinates.current.x - 2) * zoom.current,
        delta.current.y +
          (currentDrawingCoordinates.current.y - 2) * zoom.current,
        3 * zoom.current,
        3 * zoom.current
      );
      /// Draw the actual circle
      ctx.beginPath();
      ctx.arc(
        delta.current.x + currentDrawingCoordinates.current.x * zoom.current,
        delta.current.y + currentDrawingCoordinates.current.y * zoom.current,
        Math.hypot(
          currentDrawingCoordinates.current.x - tmpDrawingCoordinates.current.x,
          currentDrawingCoordinates.current.y - tmpDrawingCoordinates.current.y
        ) * zoom.current,
        0,
        2 * Math.PI
      );
      ctx.lineWidth = currentThickness.current * zoom.current;
      ctx.strokeStyle = "blue";
      ctx.stroke();
    }

    /// Draw rectangles
    shapes.current.rectangles.forEach((rectangle, id) => {
      if (
        highlightedId.current === "rectangles" ||
        highlightedId.current === id
      ) {
        ctx.strokeStyle = "blue";
      } else {
        ctx.strokeStyle = "red";
      }
      ctx.beginPath();
      ctx.moveTo(
        delta.current.x + rectangle.corner1.x * zoom.current,
        delta.current.y + rectangle.corner1.y * zoom.current
      );
      ctx.lineTo(
        delta.current.x + rectangle.corner2.x * zoom.current,
        delta.current.y + rectangle.corner2.y * zoom.current
      );
      ctx.lineTo(
        delta.current.x + rectangle.corner3.x * zoom.current,
        delta.current.y + rectangle.corner3.y * zoom.current
      );
      ctx.lineTo(
        delta.current.x + rectangle.corner4.x * zoom.current,
        delta.current.y + rectangle.corner4.y * zoom.current
      );
      ctx.lineTo(
        delta.current.x + rectangle.corner1.x * zoom.current,
        delta.current.y + rectangle.corner1.y * zoom.current
      );
      ctx.lineWidth = rectangle.thickness * zoom.current;
      ctx.stroke();
    });

    /// Draw temporary rectangles but we only have 1 corner + mouse
    if (
      currentTool === "rectangle" &&
      currentDrawingCoordinates.current &&
      !currentDrawingCoordinates2.current &&
      tmpDrawingCoordinates.current
    ) {
      ctx.beginPath();
      ctx.moveTo(
        delta.current.x + currentDrawingCoordinates.current.x * zoom.current,
        delta.current.y + currentDrawingCoordinates.current.y * zoom.current
      );
      ctx.lineTo(
        delta.current.x + tmpDrawingCoordinates.current.x * zoom.current,
        delta.current.y + tmpDrawingCoordinates.current.y * zoom.current
      );
      ctx.lineWidth = currentThickness.current * zoom.current;
      ctx.strokeStyle = "blue";
      ctx.stroke();
    }

    /// Draw temporary rectangles but we have 2 corners + mouse
    if (
      currentTool === "rectangle" &&
      currentDrawingCoordinates.current &&
      currentDrawingCoordinates2.current &&
      tmpDrawingCoordinates.current
    ) {
      const corners = createRectangleFromSegmentAndPoint(
        currentDrawingCoordinates.current,
        currentDrawingCoordinates2.current,
        tmpDrawingCoordinates.current
      );
      ctx.beginPath();
      ctx.moveTo(
        delta.current.x + corners.corner1.x * zoom.current,
        delta.current.y + corners.corner1.y * zoom.current
      );
      ctx.lineTo(
        delta.current.x + corners.corner2.x * zoom.current,
        delta.current.y + corners.corner2.y * zoom.current
      );
      ctx.lineTo(
        delta.current.x + corners.corner3.x * zoom.current,
        delta.current.y + corners.corner3.y * zoom.current
      );
      ctx.lineTo(
        delta.current.x + corners.corner4.x * zoom.current,
        delta.current.y + corners.corner4.y * zoom.current
      );
      ctx.lineTo(
        delta.current.x + corners.corner1.x * zoom.current,
        delta.current.y + corners.corner1.y * zoom.current
      );
      ctx.lineWidth = currentThickness.current * zoom.current;
      ctx.strokeStyle = "blue";
      ctx.stroke();
    }
  }, [createRectangleFromSegmentAndPoint, currentTool, map]);

  const handleWheel = (event) => {
    event.preventDefault();

    /// TODO: Make it zoom at the mouse position or center of the canvas
    zoom.current = event.deltaY > 0 ? zoom.current / 1.2 : zoom.current * 1.2;

    requestAnimationFrame(drawElements);
  };

  const handleMouseDown = (event) => {
    /// Ignore right clicks
    if (event.button === 2) {
      return;
    }

    mouseDown.current = true;
    /// Check if we're using the panning tool or panning using the middle click
    if (currentTool === "panning") {
      panStart.current.x = event.clientX;
      panStart.current.y = event.clientY;
      return;
    } else if (event.button === 1) {
      panStart.current.x = event.clientX;
      panStart.current.y = event.clientY;
      middleButtonPan.current = true;
      return;
    }

    /// Get the clicked x and y coordinates
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = (event.clientX - rect.left - delta.current.x) / zoom.current;
    const y = (event.clientY - rect.top - delta.current.y) / zoom.current;

    switch (currentTool) {
      case "point":
        /// Create a new group of points
        currentId.current = crypto.randomUUID();
        shapes.current.points.set(currentId.current, [{ x, y }]);
        setShapeUpdate(!shapeUpdate);
        break;
      case "line":
        /// If we already have currentDrawingCoordinates, it's the second point of a line so we save the line
        if (currentDrawingCoordinates.current) {
          currentId.current = crypto.randomUUID();
          shapes.current.lines.set(currentId.current, {
            thickness: 1,
            points: [
              {
                x: currentDrawingCoordinates.current.x,
                y: currentDrawingCoordinates.current.y,
              },
              { x, y },
            ],
          });
          currentDrawingCoordinates.current = null;
          tmpDrawingCoordinates.current = null;
          setShapeUpdate(!shapeUpdate);
        } else {
          /// If we don't have currentDrawingCoordinates yet, it's the first point of a line
          currentDrawingCoordinates.current = { x, y };
        }
        break;
      case "multiline":
        if (currentDrawingCoordinates.current) {
          /// If we already have currentDrawingCoordinates, and we already saved the multi line in shapes then we just need to add new points to it
          if (shapes.current.lines.has(currentId.current)) {
            shapes.current.lines.get(currentId.current).points.push({ x, y });
          } else {
            /// If we already have currentDrawingCoordinates, but we haven't already saved the multi line in shapes then we add a new line to it
            shapes.current.lines.set(currentId.current, {
              thickness: 1,
              points: [
                {
                  x: currentDrawingCoordinates.current.x,
                  y: currentDrawingCoordinates.current.y,
                },
                { x, y },
              ],
            });
          }
          currentDrawingCoordinates.current = tmpDrawingCoordinates.current;
          tmpDrawingCoordinates.current = null;
          setShapeUpdate(!shapeUpdate);
        } else {
          /// If we don't have currentDrawingCoordinates yet, it's the first point of the multiline
          currentDrawingCoordinates.current = { x, y };
        }
        break;
      case "circle":
        /// If we already have currentDrawingCoordinates, we already have the origin of the circle so the next point determine the radius of the circle
        if (currentDrawingCoordinates.current) {
          currentId.current = crypto.randomUUID();
          shapes.current.circles.set(currentId.current, {
            thickness: 1,
            origin: {
              x: currentDrawingCoordinates.current.x,
              y: currentDrawingCoordinates.current.y,
            },
            radius: Math.hypot(
              currentDrawingCoordinates.current.x - x,
              currentDrawingCoordinates.current.y - y
            ),
          });
          currentDrawingCoordinates.current = null;
          tmpDrawingCoordinates.current = null;
          setShapeUpdate(!shapeUpdate);
        } else {
          /// If we don't have currentDrawingCoordinates yet, we set it, it will be the origin of the circle
          currentDrawingCoordinates.current = { x, y };
        }
        break;
      case "rectangle":
        if (currentDrawingCoordinates.current) {
          /// If we have currentDrawingCoordinates and currentDrawingCoordinates2, we already have 2 corners and the 3rd point will represent the size of the rectangle from which we'll calculate the 3rd and 4th corners
          if (currentDrawingCoordinates2.current) {
            /// Got 2 corners, 3rd point make a rectangle
            currentId.current = crypto.randomUUID();
            const corners = createRectangleFromSegmentAndPoint(
              currentDrawingCoordinates.current,
              currentDrawingCoordinates2.current,
              { x, y }
            );
            shapes.current.rectangles.set(currentId.current, {
              thickness: 1,
              corner1: corners.corner1,
              corner2: corners.corner2,
              corner3: corners.corner3,
              corner4: corners.corner4,
            });
            currentDrawingCoordinates.current = null;
            currentDrawingCoordinates2.current = null;
            tmpDrawingCoordinates.current = null;
            setShapeUpdate(!shapeUpdate);
          } else {
            /// If we have currentDrawingCoordinates and currentDrawingCoordinates2, we already have 2 corners and the 3rd point will represent the size of the rectangle from which we'll calculate the 3rd and 4th corners
            /// Got 1 corner, next point make a line
            currentDrawingCoordinates2.current = { x, y };
          }
        } else {
          /// If we don't have currentDrawingCoordinates yet, it will be the first corner of the rectangle
          currentDrawingCoordinates.current = { x, y };
        }
        break;
      default:
        break;
    }

    /// Redraw all elements
    requestAnimationFrame(drawElements);
  };

  const handleMove = (event) => {
    /// Drawing points and panning works when we move with the left click pressed
    if (mouseDown.current) {
      if (middleButtonPan.current || currentTool === "panning") {
        const deltaX = event.clientX - panStart.current.x;
        const deltaY = event.clientY - panStart.current.y;
        delta.current.x = delta.current.x + deltaX;
        delta.current.y = delta.current.y + deltaY;
        requestAnimationFrame(drawElements);
        panStart.current.x = event.clientX;
        panStart.current.y = event.clientY;
      } else if (currentTool === "point") {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = (event.clientX - rect.left - delta.current.x) / zoom.current;
        const y = (event.clientY - rect.top - delta.current.y) / zoom.current;
        shapes.current.points.get(currentId.current).push({ x, y });
        setShapeUpdate(!shapeUpdate);
        requestAnimationFrame(drawElements);
      }
    } else {
      /// While drawing the other shapes is only done when the left click is pressed so we show temporary lines and other shapes when moving
      if (
        (currentTool === "line" ||
          currentTool === "multiline" ||
          currentTool === "circle" ||
          currentTool === "rectangle") &&
        currentDrawingCoordinates.current
      ) {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = (event.clientX - rect.left - delta.current.x) / zoom.current;
        const y = (event.clientY - rect.top - delta.current.y) / zoom.current;
        tmpDrawingCoordinates.current = { x, y };
        requestAnimationFrame(drawElements);
      }
    }
  };

  const handleMouseUp = (event) => {
    /// Ignore right click
    if (event.button === 2) {
      return;
    }
    mouseDown.current = false;

    /// For middle click mouse panning
    if (event.button === 1) {
      middleButtonPan.current = false;
    }
  };

  const setCurrentObstacles = useCallback(
    (obstacles) => {
      obstacles["lines"].forEach((line_obstacle) => {
        const points = [];
        line_obstacle["points"].forEach((point) => {
          points.push({
            x: (point.x - map.origin_x) / map.resolution,
            y:
              mapImage.current.height -
              (point.y - map.origin_y) / map.resolution,
          });
        });
        shapes.current.lines.set(line_obstacle["name"], {
          thickness: 1,
          points: points,
        });
      });

      obstacles["boxes"].forEach((box_obstacle) => {
        shapes.current.rectangles.set(box_obstacle["name"], {
          thickness: 1,
          corner1: {
            x: (box_obstacle["corner1"].x - map.origin_x) / map.resolution,
            y:
              mapImage.current.height -
              (box_obstacle["corner1"].y - map.origin_y) / map.resolution,
          },
          corner2: {
            x: (box_obstacle["corner2"].x - map.origin_x) / map.resolution,
            y:
              mapImage.current.height -
              (box_obstacle["corner2"].y - map.origin_y) / map.resolution,
          },
          corner3: {
            x: (box_obstacle["corner3"].x - map.origin_x) / map.resolution,
            y:
              mapImage.current.height -
              (box_obstacle["corner3"].y - map.origin_y) / map.resolution,
          },
          corner4: {
            x: (box_obstacle["corner4"].x - map.origin_x) / map.resolution,
            y:
              mapImage.current.height -
              (box_obstacle["corner4"].y - map.origin_y) / map.resolution,
          },
        });
      });

      obstacles["circles"].forEach((circle_obstacle) => {
        shapes.current.circles.set(circle_obstacle["name"], {
          thickness: 1,
          origin: {
            x: (circle_obstacle["origin"].x - map.origin_x) / map.resolution,
            y:
              mapImage.current.height -
              (circle_obstacle["origin"].y - map.origin_y) / map.resolution,
          },
          radius: circle_obstacle["radius"] / map.resolution,
        });
      });

      obstacles["points"].forEach((point_obstacle) => {
        const points = [];
        point_obstacle["points"].forEach((point) => {
          points.push({
            x: (point.x - map.origin_x) / map.resolution,
            y:
              mapImage.current.height -
              (point.y - map.origin_y) / map.resolution,
          });
        });
        shapes.current.points.set(point_obstacle["name"], points);
      });

      /// Redraw all elements
      setShapeUpdate(!shapeUpdate);
      requestAnimationFrame(drawElements);
    },
    [drawElements, map, shapeUpdate]
  );

  const handleResize = useCallback(() => {
    /// Adjust canvas resolution based on device pixel ratio
    const ctx = canvasRef.current.getContext("2d");

    const devicePixelRatio = window.devicePixelRatio || 1;
    const backingStoreRatio =
      ctx.webkitBackingStorePixelRatio ||
      ctx.mozBackingStorePixelRatio ||
      ctx.msBackingStorePixelRatio ||
      ctx.oBackingStorePixelRatio ||
      ctx.backingStorePixelRatio ||
      1;

    const ratio = devicePixelRatio / backingStoreRatio;
    canvasRef.current.width = canvasRef.current.clientWidth * ratio - 1;
    canvasRef.current.height = canvasRef.current.clientHeight * ratio - 1;

    ctx.scale(ratio, ratio);
    requestAnimationFrame(drawElements);
  }, [drawElements]);

  /// For initialization
  useEffect(() => {
    if (!initialized.current && "image" in map) {
      getObstacleLayer(fleetId, robotId, map.name)
        .then(function (data) {
          initialObstacles.current = data;
          setCurrentObstacles(data);
        })
        .catch((error) => {
          console.error(error);
        });

      handleResize();

      robotImage.current.src = robotImagePng;
      robotImage.current.onload = () => {
        console.log(
          "Robot image size",
          robotImage.current.width,
          robotImage.current.height
        );
        robotImageScale.current = 1.5 / (160 * map.resolution);
      };

      mapImage.current.src = "data:image/jpeg;base64," + map.image;
      mapImage.current.onload = () => {
        imageInitialized.current = true;
        console.log(
          "Map image size",
          mapImage.current.width,
          mapImage.current.height
        );
        /// Redraw elements on initial load
        requestAnimationFrame(drawElements);
      };

      initialized.current = true;
    }
  }, [drawElements, fleetId, handleResize, map, robotId, setCurrentObstacles]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  const handleClearDialogYes = () => {
    /// Close the clear dialog and remove all the shapes
    setOpenClearDialog(false);
    shapes.current.points.clear();
    shapes.current.circles.clear();
    shapes.current.lines.clear();
    shapes.current.rectangles.clear();
    setShapeUpdate(!shapeUpdate);
    requestAnimationFrame(drawElements);
  };

  const handleClearDialogNo = () => {
    /// Close the clear dialog
    setOpenClearDialog(false);
  };

  const clearShapes = useCallback(() => {
    /// If we have shapes, open the clear dialog, else ignore
    if (
      shapes.current.points.size > 0 ||
      shapes.current.circles.size > 0 ||
      shapes.current.lines.size > 0 ||
      shapes.current.rectangles.size > 0
    ) {
      setOpenClearDialog(true);
    }
  }, []);

  const resetShapes = useCallback(() => {
    shapes.current.points.clear();
    shapes.current.circles.clear();
    shapes.current.lines.clear();
    shapes.current.rectangles.clear();
    setCurrentObstacles(initialObstacles.current);
    setShapeUpdate(!shapeUpdate);
    requestAnimationFrame(drawElements);
  }, [drawElements, setCurrentObstacles, shapeUpdate]);

  const saveShapes = useCallback(() => {
    let obstacles = {
      lines: [],
      boxes: [],
      circles: [],
      points: [],
    };

    shapes.current.lines.forEach((line, id) => {
      let points = [];

      for (let i = 0; i < line.points.length; i++) {
        points.push({
          x: line.points[i].x * map.resolution + map.origin_x,
          y:
            (mapImage.current.height - line.points[i].y) * map.resolution +
            map.origin_y,
        });
      }
      obstacles.lines.push({
        name: id,
        filled: false,
        points: points,
      });
    });

    shapes.current.rectangles.forEach((rectangle, id) => {
      obstacles.boxes.push({
        name: id,
        filled: false,
        corner1: {
          x: rectangle.corner1.x * map.resolution + map.origin_x,
          y:
            (mapImage.current.height - rectangle.corner1.y) * map.resolution +
            map.origin_y,
        },
        corner2: {
          x: rectangle.corner2.x * map.resolution + map.origin_x,
          y:
            (mapImage.current.height - rectangle.corner2.y) * map.resolution +
            map.origin_y,
        },
        corner3: {
          x: rectangle.corner3.x * map.resolution + map.origin_x,
          y:
            (mapImage.current.height - rectangle.corner3.y) * map.resolution +
            map.origin_y,
        },
        corner4: {
          x: rectangle.corner4.x * map.resolution + map.origin_x,
          y:
            (mapImage.current.height - rectangle.corner4.y) * map.resolution +
            map.origin_y,
        },
      });
    });

    shapes.current.circles.forEach((circle, id) => {
      obstacles.circles.push({
        name: id,
        filled: false,
        origin: {
          x: circle.origin.x * map.resolution + map.origin_x,
          y:
            (mapImage.current.height - circle.origin.y) * map.resolution +
            map.origin_y,
        },
        radius: circle.radius * map.resolution,
      });
    });

    shapes.current.points.forEach((points, id) => {
      let points_res = [];

      points.forEach((point) => {
        points_res.push({
          x: point.x * map.resolution + map.origin_x,
          y:
            (mapImage.current.height - point.y) * map.resolution + map.origin_y,
        });
      });

      obstacles.points.push({
        name: id,
        points: points_res,
      });
    });

    saveObstacleLayer(robotId, map.name, obstacles)
      .then(function (data) {
        // TODO: Show mui alert
        console.log("Saved obstacles");
      })
      .catch((error) => {
        // TODO: Show mui alert
        console.error(error);
      });
  }, [map.name, map.origin_x, map.origin_y, map.resolution, robotId]);

  const centerCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    const centerX = (rect.width - mapImage.current.width * zoom.current) / 2;
    const centerY = (rect.height - mapImage.current.height * zoom.current) / 2;

    delta.current.x = centerX;
    delta.current.y = centerY;

    requestAnimationFrame(drawElements);
  }, [drawElements]);

  const resetCurrentCoordinates = useCallback(() => {
    currentDrawingCoordinates.current = null;
    tmpDrawingCoordinates.current = null;
    currentDrawingCoordinates2.current = null;
    requestAnimationFrame(drawElements);
  }, [drawElements]);

  const setHighlightedId = useCallback(
    (id) => {
      highlightedId.current = id;
      requestAnimationFrame(drawElements);
    },
    [drawElements]
  );

  const deleteShapeById = useCallback(
    (type, id) => {
      switch (type) {
        case "points":
          shapes.current.points.delete(id);
          break;
        case "lines":
          shapes.current.lines.delete(id);
          break;
        case "circles":
          shapes.current.circles.delete(id);
          break;
        case "rectangles":
          shapes.current.rectangles.delete(id);
          break;
        default:
          console.error("Deleting shape by id called with unknown type", type);
          return;
      }
      setShapeUpdate(!shapeUpdate);
      currentDrawingCoordinates.current = null;
      tmpDrawingCoordinates.current = null;
      currentDrawingCoordinates2.current = null;
      requestAnimationFrame(drawElements);
    },
    [drawElements, shapeUpdate]
  );

  useInterval(() => {
    if (
      initialized.current &&
      robotStates &&
      robotStates.has(robotId) &&
      robotStates.get(robotId).location.level_name === map.name
    ) {
      robotPose.current = {
        x:
          (robotStates.get(robotId).location.x - map.origin_x) / map.resolution,
        y:
          mapImage.current.height -
          (robotStates.get(robotId).location.y - map.origin_y) / map.resolution,
        yaw: -robotStates.get(robotId).location.yaw + (90 * Math.PI) / 180,
      };
      robotScanData.current = robotStates.get(robotId).scan_data;
      requestAnimationFrame(drawElements);
    }
  }, 1000);

  return (
    <Box
      display="flex"
      sx={{ flexDirection: "column", flexGrow: 1 }}
      style={theme.background_secondary}
    >
      <MapEditorToolMenu
        currentTool={currentTool}
        setCurrentTool={setCurrentTool}
        setExpanded={setExpanded}
        resetCurrentCoordinates={resetCurrentCoordinates}
        currentId={currentId}
        centerCanvas={centerCanvas}
        clearShapes={clearShapes}
        resetShapes={resetShapes}
        saveShapes={saveShapes}
      ></MapEditorToolMenu>
      <Box display="flex" sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            sx={{
              display: "flex",
            }}
          >
            <canvas
              ref={canvasRef}
              style={{
                imageRendering: "pixelated",
                flexGrow: 1,
              }}
              onMouseDown={handleMouseDown}
              onWheel={handleWheel}
              onMouseMove={handleMove}
              onMouseUp={handleMouseUp}
              onMouseOut={handleMouseUp}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              display: "flex",
              backgroundColor: "white",
              overflow: "scroll",
              borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
              maxHeight: canvasRef.current
                ? canvasRef.current.clientHeight
                : "100vh",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <ShapesList
                shapes={shapes.current}
                setHighlightedId={setHighlightedId}
                deleteShapeById={deleteShapeById}
                expanded={expanded}
                setExpanded={setExpanded}
              ></ShapesList>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <AlertDialog
        handleYes={handleClearDialogYes}
        handleNo={handleClearDialogNo}
        open={openClearDialog}
        title={"Remove all the shapes?"}
        content={"This will delete all the shapes for this map."}
        yesText={"Yes"}
        noText={"Cancel"}
      ></AlertDialog>
    </Box>
  );
};

export default MapEditor;
