import MapEditService from "../services/map_edit.service";

export async function getMaps(fleet_id) {
  try {
    const response = await MapEditService.getMapsService(fleet_id);

    return Promise.resolve(response.data);
  } catch (err) {
    console.error(err);
    return Promise.resolve({ error: err });
  }
}

export async function getMapById(map_id) {
  try {
    const response = await MapEditService.getMapByIdService(map_id);

    return Promise.resolve(response.data);
  } catch (err) {
    console.error(err);
    return Promise.resolve({ error: err });
  }
}

export async function saveObstacleLayer(robot_id, map_name, obstacles) {
  try {
    const response = await MapEditService.saveObstacleLayerService(
      robot_id,
      map_name,
      obstacles
    );

    return Promise.resolve(response.data);
  } catch (err) {
    console.error(err);
    return Promise.resolve({ error: err });
  }
}

export async function getObstacleLayer(fleet_id, robot_id, map_name) {
  try {
    const response = await MapEditService.getObstacleLayerService(
      fleet_id,
      robot_id,
      map_name
    );

    return Promise.resolve(response.data);
  } catch (err) {
    console.error(err);
    return Promise.resolve({ error: err });
  }
}
