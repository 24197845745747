import get_rest_instance from "../../shared/services/common.service";

class ControlService {
  getPathsAndGoalsService(map_id) {
    return get_rest_instance("server").get("/paths_and_goals", {
      params: { map_id: map_id },
    });
  }

  getMapService(robot_id, map_name) {
    return get_rest_instance("server").get("/map_by_name", {
      params: { robot_id: robot_id, map_name: map_name },
    });
  }

  sendControlCoordService(robot_id, destination) {
    return get_rest_instance("server").post("/control_coord", {
      robot_id: robot_id,
      destination: destination,
    });
  }

  sendControlPathService(robot_id, path) {
    return get_rest_instance("server").post("/control_path", {
      robot_id: robot_id,
      path: path,
    });
  }

  sendControlPathNameService(robot_id, map_name, path_name) {
    return get_rest_instance("server").post("/control_path_name", {
      robot_id: robot_id,
      map_name: map_name,
      path_name: path_name,
    });
  }

  sendModeRequestService(robot_id, mode) {
    return get_rest_instance("server").post("/mode_request", {
      robot_id: robot_id,
      mode: mode,
    });
  }
}

export default new ControlService();
