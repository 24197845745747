import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { useMediaQuery } from "../../shared/hooks/mediaQuery";
import { FleetContext } from "../../shared/components/page_base";
import { useInterval } from "../../shared/hooks/useInterval";
import MapList from "./map_list";
import { getMaps } from "../actions/map_edit.action";

function RobotMapList(props) {
  const { selectedFleet, selectMap } = props;
  const { robotStates } = useContext(FleetContext);
  const [fleetState, setFleetState] = useState(new Map());
  const [robotId, setRobotId] = useState("");
  const theme = useTheme();
  const [maps, setMaps] = useState([]);

  const isLg = useMediaQuery("(min-width: 1200px)");
  const isMd = useMediaQuery("(min-width: 900px)");
  const isSm = useMediaQuery("(min-width: 768px)");

  const selectRobot = useCallback(
    (robot_id, robotItem) => {
      if (robotId === robot_id) {
        setRobotId("");
      } else {
        setRobotId(robot_id);
      }
      // navigate("/dispatch_control/" + fleetId + "/" + robot_id);
    },
    [robotId]
  );

  useInterval(() => {
    if (selectedFleet && robotStates && robotStates.size !== 0) {
      let tmpFleetState = new Map();
      selectedFleet.robots.forEach((robotItem) => {
        tmpFleetState.set(robotItem.id, robotStates.get(robotItem.id));
      });
      setFleetState(tmpFleetState);
    }
  }, 1000);

  useEffect(() => {
    if (selectedFleet) {
      getMaps(selectedFleet.id)
        .then(function (data) {
          console.log("Got maps for fleet", selectedFleet.id, data);
          setMaps(data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [selectedFleet]);

  return (
    <React.Fragment>
      <Box
        display="flex"
        style={{
          flexDirection: "column",
          gap: "0.5rem",
          paddingLeft: "1.5rem",
          paddingRight: "1.5rem",
        }}
      >
        <Grid
          container
          style={theme.background_primary}
          sx={{
            paddingLeft: "1rem",
            paddingRight: "1rem",
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
          }}
        >
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Typography style={{ fontWeight: 700 }}>Name</Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Typography style={{ fontWeight: 700 }}>Status</Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            style={{ display: isMd ? "" : "none" }}
          >
            <Typography style={{ fontWeight: 700 }}>Current Map</Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Typography style={{ fontWeight: 700 }}>Active</Typography>
          </Grid>
        </Grid>

        {selectedFleet &&
        selectedFleet.robots &&
        selectedFleet.robots.length !== 0
          ? selectedFleet.robots.map((robotItem) => (
              <React.Fragment key={robotItem.id + "-fragment"}>
                <Button
                  key={robotItem.id + "-robot"}
                  style={theme.linear_background_tertiary}
                  sx={{
                    padding: "1rem",
                    borderRadius: 0,
                    textAlign: "left",
                    textTransform: "none",
                    color: "black",
                  }}
                  onClick={() => {
                    selectRobot(robotItem.id, robotItem);
                  }}
                >
                  <Grid container>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                      <Typography>{robotItem.name}</Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                      <Typography>
                        {fleetState &&
                        fleetState.has(robotItem.id) &&
                        "mode" in fleetState.get(robotItem.id) &&
                        fleetState
                          .get(robotItem.id)
                          ["mode"].substring(
                            16,
                            fleetState.get(robotItem.id)["mode"].length - 1
                          )
                          ? fleetState
                              .get(robotItem.id)
                              ["mode"].substring(
                                16,
                                fleetState.get(robotItem.id)["mode"].length - 1
                              )
                          : "N/A"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                      style={{ display: isMd ? "" : "none" }}
                    >
                      <Typography>
                        {fleetState &&
                        fleetState.has(robotItem.id) &&
                        fleetState.get(robotItem.id)["location"]["level_name"]
                          ? fleetState.get(robotItem.id)["location"][
                              "level_name"
                            ]
                          : "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                      <Typography>
                        {robotItem.active ? "True" : "False"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Button>
                {robotId && robotId === robotItem.id ? (
                  <MapList
                    key={robotItem.id + "-map-list"}
                    selectMap={selectMap}
                    robotId={robotId}
                    maps={maps}
                  ></MapList>
                ) : (
                  ""
                )}
              </React.Fragment>
            ))
          : "No robot available, try refreshing the page"}
      </Box>
    </React.Fragment>
  );
}

export default RobotMapList;
