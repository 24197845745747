import React from "react";
import Settings from "./settings";

function SettingsPage() {
  document.title = "Settings | Cyberworks Robotics Fleet Management";
  return (
    <React.Fragment>
      <Settings />
    </React.Fragment>
  );
}

export default SettingsPage;
