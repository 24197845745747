import get_rest_instance from "../../shared/services/common.service";
import { KEYCLOAK_CLIENT, KEYCLOAK_REALM } from "../../constant";

class AuthService {
  loginService(username, password) {
    /**
     * Method to make rest api call with key cloak server for authentication
     * @param username (string)
     * @param password (string)
     * @return Promise<Response>
     */
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);
    params.append("grant_type", "password");
    params.append("client_id", KEYCLOAK_CLIENT);

    return get_rest_instance("login").post(
      "/realms/" + KEYCLOAK_REALM + "/protocol/openid-connect/token",
      params
    );
  }

  refreshTokenService(refresh_token) {
    /**
     * Method to make rest api call with key cloak server for refreshing the token
     * @param refresh_token
     * @return Promise<Response>
     */
    const params = new URLSearchParams();
    params.append("refresh_token", refresh_token);
    params.append("grant_type", "refresh_token");
    params.append("client_id", KEYCLOAK_CLIENT);

    return get_rest_instance("login").post(
      "/realms/" + KEYCLOAK_REALM + "/protocol/openid-connect/token",
      params
    );
  }

  logoutService(refresh_token) {
    /**
     * Method to make rest api call with key cloak server for logging out
     */
    const params = new URLSearchParams();
    params.append("client_id", KEYCLOAK_CLIENT);
    params.append("refresh_token", refresh_token);

    return get_rest_instance("login").post(
      "/realms/" + KEYCLOAK_REALM + "/protocol/openid-connect/logout",
      params
    );
  }
}

export default new AuthService();
